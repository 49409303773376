import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, } from "reactstrap"
import ReactDatatable from '@ashvin27/react-datatable';
 
//Import Action to copy breadcrumb items from local state to redux state

export default class LoginHistory extends Component {
    constructor(props) {
        super(props);
        this.columns = this.props.columns;
        this.config = {
            page_size: 5,
            length_menu: [5 ,10, 20, 50],
            show_filter: false,
            show_length_menu: false,
            show_pagination: false,
        }
        this.state = {
            records:this.props.records,
        }
    }

    render() {
        return (
            <React.Fragment className={'custom-table'}>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className="h4 mb-10">{this.props.tableTitle}</CardTitle>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}

