import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import ReactDatatable from '@ashvin27/react-datatable';
import Helmet from "react-helmet"
import { getPages, getPermission, getRoles, isAllowed, uInfo, bcrypt, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from 'sweetalert';
import { Link } from "react-router-dom";

export default class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "#",
        key: "id",
        sortable: true
      },
      {
        text: "Name",
        key: "name",
        sortable: true
      },
      {
        text: "Library For",
        key: "type",
        sortable: true
      },
      {
        text: "Created At",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <Link
                type="button"
                color="info"
                className="btn btn-primary btn-sm"
                to={"edit/" + bcrypt(record.id)}

              >
                <i className="mdi mdi-pencil"></i>
              </Link>
              {" | "}
              <button
                className="btn btn-primary btn-sm mb-1 mr-1"
                onClick={this.deleteRecord.bind(this, record, index)}
              >
                Delete
              </button>

            </Fragment>
          );
        },
      }
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true
      },

    }
    this.state = {
      records: [],
      total_pages: 0,
      modal: false,
      record: {},
      selectedMulti: null,
      selectedPermissionMulti: null,
      pages: '',
      permission: '',
      page_name: "Role",
    }
  }

  editRecord = (record, index) => {
    let array = [];
    var pages = [];
    let p_array = [];
    let p_permission = [];

    let all_access = record.pages.split(',');
    all_access.map((access, i) => {
      pages.push(access);
      array.push({ 'label': access, 'value': access });
    });

    // let all_permission = record.permission.split(',');
    // all_permission.map((permission, i) => {
    //   p_permission.push(permission);
    //   p_array.push({ 'label': permission, 'value': permission });
    // });

    this.setState({
      modal: !this.state.modal,
      record: record,
      selectedMulti: array,
      selectedPermissionMulti: p_array,
      pages: pages.toString(),
      permission: p_permission.toString()
    });



    console.log("Edit record", index, record);
  }





  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + 'delete/permission/library', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({ 'id': record.id }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                this.getData();
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        }
      });

  }

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "") => {

    fetch(`${ApiUrl}` + 'permission/library' + queryString, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, 'data')
        if (data.status === true) {
          this.setState({
            total_pages: data.data.total,
            records: data.data.records
          })
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }


  tableChangeHandler = data => {
    let queryString = Object.keys(data).map((key) => {
      if (key === "sort_order" && data[key]) {
        return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      }

    }).join('&');
    this.getData('?' + queryString);
  }



  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} Tables </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>Permission Library</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_nam} List</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Link
                type="button"
                color="info"
                className="btn btn-info"
                to={'add'}
              >
                <i className="mdi mdi-plus"> </i>
                Create
              </Link>
            </div>
          </Col>
        </Row>

        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment >
    )
  }
}

