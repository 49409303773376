import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  isAllowed,
  logout,
  uInfo,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import Dropdown from 'react-bootstrap/Dropdown';

export default class UsersDatatableTables extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "ID",
        key: "user_id",
      },
      {
        text: "User Name",
        key: "email",
      },
      {
        text: "Team",
        key: "team",
      },
      {
        text: "Last Login",
        key: "created_at",
      },
      {
        text: "Logout",
        key: "logout_agent",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
                <a
                  onClick={this.logoutAgents.bind(this, record, index)}
                >
               <i className="fas fa-power-off" aria-hidden="true"></i>

                </a>
            
            </Fragment>
          );
        },
      },
      {
        text: "Date Created",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                type="button"
                color="info"
                className="btn btn-primary btn-sm mb-1 mr-5"
                onClick={() => this.props.history.push("/agents/edit/" + bcrypt(record.id))}
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm mb-1"
                onClick={this.deleteRecord.bind(this, record, index)}
              >
               <i className="fas fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      settingchangemodal: false,
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      coachlimit: "",
      page_name: "Agents",
      customDate: true,
      ClientSubcribeModal: false,
      currentClient: {
        id: "",
        email_status: "",
        call_status: "",
        groups: [],
        tags: [],
      },
    };

    this.loginAsSubCoach = this.loginAsSubCoach.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this)

  }
  logoutAgents = (record, index) => {
        let url = `${ApiUrl}` + "update/groupleads/" + record.group_id;

        fetch(`${url}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify(),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
              //this.getData();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    };
  loginAsSubCoach = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to login this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isDelete) => {
      if (isDelete) {
        fetch(`${ApiUrl}` + "login/sub/coach", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ user_id: record.id, to_user_id: uInfo().user_id }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status == true) {
              logout();
              localStorage.setItem("authUser", JSON.stringify(response.data.user));
              localStorage.setItem("switchUser", window.btoa(encodeURIComponent(response.data.users)));
              setTimeout(this.props.history.push("/"), 4000);
            } else {
              toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };


  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/coachpartner", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id, coach_id: record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  componentDidMount() {
    this.getData();
    this.getcoachlimit();
  }
  closemodal() {
    this.setState({
      settingchangemodal: !this.state.settingchangemodal,
    });
  }
  getcoachlimit() {
    fetch(`${ApiUrl}` + "check/coachlimit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            coachlimit: data.data.limit,
          });

          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  checkcoachlimit = () => {
    // if (this.state.coachlimit.remaining_user == 0) {
    //   this.setState({
    //     settingchangemodal: !this.state.settingchangemodal,
    //   });
    //   this.props.history.push("/settings/coachpartner/packages");
    // } else {
    //   this.props.history.push("/settings/coachpartner/add");
    // }
    this.props.history.push("/agents/add");
  };
  getData = (queryString = "?all=1", data) => {
    fetch(`${ApiUrl}` + "coachpartners" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response, "Coach Partner data");
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            //     roles: response.data.roles,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString + "&all=1", this.state.filter);
  };

  openClientSubcribeModal = (client, index) => {
    this.setState(
      {
        currentClient: client,
      },
      () =>
        this.setState({
          ClientSubcribeModal: true,
        })
    );
  };

  ClientSubcribeSubmit = async (event, values) => {
    values.id = this.state.currentClient.id;
    values.coach_id = this.state.currentClient.coach_id;
    fetch(`${ApiUrl}` + "client/subscribe/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            ClientSubcribeModal: false,
          });
          this.getData();
          this.setState({
            currentClient: {
              id: "",
              email_status: "",
              call_status: "",
            },
          });
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            List
          </title>
        </Helmet>

        <Row>
          <Col className="col-12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name} List</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name} List</Link>
                    </li>
                  </ol>
                </div>
              </Col>

              <Col sm={2}>
                {/* <div className="page-title-box text-align-right">
                  <h4> Available Limit : {this.state.coachlimit.user_limit}</h4>
                </div>
                <div className="page-title-box text-align-right">
                  <h4>  Remaining Limit : {this.state.coachlimit.remaining_user}</h4>
                </div> */}
              </Col>
              <Col sm={4}>
                <div className="page-title-box text-align-right">
                  {/* && this.state.coachlimit.remaining_user > 0 */}
                  {(isAllowed(uInfo(), ["can_create"])) && (
                    <Link
                      type="button"
                      color="info"
                      className="btn btn-info"
                      // to={"/settings/coachpartner/add"}
                      onClick={() => this.checkcoachlimit()}
                    >
                      <i className="mdi mdi-plus"> </i>
                      Create
                    </Link>
                  )}

                  <Link
                    type="button"
                    color="primary"
                    className="btn btn-primary ml-5 d-none"
                    to={"/users/permissionLibrary/list"}

                  >
                    Permission Library
                  </Link>

                </div>
              </Col>
            </Row>

            <ToastContainer />


            <Card>
              <CardBody>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.settingchangemodal}
          fade={false}
          toggle={() => this.toggle()}
        >
          <ModalBody>
            <div className="warning-box text-center">
              <span className="warning">
                <i className="mdi mdi-alert-circle"> </i>
              </span>
              <h3> Agent Limit Exceeded! </h3>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => this.closemodal()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
