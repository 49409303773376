import React, { useState, useEffect } from "react";
import { Device } from "twilio-client";
import Dialler from "./Dialler";
import KeypadButton from "./KeypadButton";
import Incoming from "./Incoming";
import OnCall from "./OnCall";
import "./Phone.css";
import states from "./states";
import FakeState from "./FakeState";

const Phone = ({ paramsters , getCallSid }) => {
  const [state, setState] = useState(states.CONNECTING);
  const [to_number, setNumber] = useState(paramsters.phoneno);
  const [from_number, setFromNumber] = useState(paramsters.from_number);
  const [client_id, setClientId] = useState(paramsters.client_id);
  const [coach_id, setCoachId] = useState(paramsters.coach_id);
  const [campaign_id] = useState(paramsters.campaign_id ? paramsters.campaign_id : 0);

  const [conn, setConn] = useState(false);
  const [device, setDevice] = useState(true);

  useEffect(() => {
    const device = new Device();

    device.setup(paramsters.token, { debug: true });

    device.on("ready", () => {
      setDevice(device);
      setState(states.READY);
    });
    device.on("connect", (connection) => {
      console.log("Connect event");
      setConn(connection);
      getCallSid(connection.parameters.CallSid);
      setState(states.ON_CALL);
    });
    device.on("disconnect", () => {
      setState(states.READY);
      setConn(null);
    });
    // device.on("incoming", (connection) => {
    //   setState(states.INCOMING);
    //   setConn(connection);
    //   connection.on("reject", () => {
    //     setState(states.READY);
    //     setConn(null);
    //   });
    // });
    device.on("cancel", () => {
      setState(states.READY);
      setConn(null);
    });
    device.on("reject", () => {
      setState(states.READY);
      setConn(null);
    });

    return () => {
      device.destroy();
      setDevice(null);
      setState(states.OFFLINE);
    };
  }, [paramsters.token]);

  const handleCall = (e) => {
    e.preventDefault();
    device.connect({
      to_number: to_number,
      from_number: from_number,
      coach_id: coach_id,
      client_id: client_id,
      campaign_id: campaign_id
    });
  };

  const handleHangup = () => {
    device.disconnectAll();
  };

  let render;
  if (conn) {
    if (
      state === states.Ready ||
      state === states.CONNECTING
    ) {
      render = <Incoming device={device} connection={conn}></Incoming>;
    } else if (state === states.ON_CALL) {
      render = <OnCall handleHangup={handleHangup} connection={conn}></OnCall>;
    }
  } else {
    render = (
      <>
        <Dialler number={to_number} setNumber={setNumber}></Dialler>
        <div className="call">
          <KeypadButton handleClick={(e) => handleCall(e)} color="green">
            <i className="fas fa-phone"></i>
          </KeypadButton>
        </div>
      </>
    );
  }
  return (
    <>
      {/* <FakeState
        currentState={state}
        setState={setState}
        setConn={setConn}
      ></FakeState> */}
      {render}
      {/* <p className="status">{state}</p> */}
    </>
  );
};

export default Phone;
