import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
class YearlySales extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    this.getData();
  }
  getData = (queryString = "?path=1", data) => {
    fetch(`${ApiUrl}` + "get/admin/subscription/log" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            manualsum: response.data.manualsum,
            stripesum: response.data.stripesum,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Subscription Revenue</h4>

                        <Row className="text-center mt-4">
                            <Col xs="6">
                                <h5 className="font-size-20">{this.state.manualsum}</h5>
                                <p className="text-muted">Manual</p>
                            </Col>
                            <Col xs="6">
                                <h5 className="font-size-20">{this.state.stripesum}</h5>
                                <p className="text-muted">Stripe</p>
                            </Col>
                         
                        </Row>

                        {/* <div id="morris-area-example" className="morris-charts morris-charts-height" dir="ltr">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="area" height="300" />
                        </div> */}
                    </CardBody>
                </Card>
      </React.Fragment>
    );
  }
}

export default YearlySales;
