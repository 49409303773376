import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Loader from 'react-loader';
import swal from 'sweetalert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default class AdminManagerCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {
        payment_method: "manual",
        payment_type: "",
      },
      page_name: this.props.match.params.id ? "Edit Interpreter" : "Create Interpreter",
      roles: [],
      packages: [],
      resellers: [],
      payment_fields_visible: 'hidden',
      isRequired: true,
      loaded: true,
    };
    // console.log(this.state.page_name, "debug");
  }

  componentDidMount() {
    if (this.state.id) {
      this.getData();
    }
    this.getPackages();
    this.getResellers();
  }
  onChangePaymentAction = (value) => {

    this.setState({ record: { payment_method: value } });
    if (value == "stripe") {
      this.setState({ payment_fields_visible: 'visible' });
      this.setState({ isRequired: true });

    } else {
      this.setState({ payment_fields_visible: 'hidden' });
      this.setState({ isRequired: false });
    }
  };
  getPackages = (role) => {
    fetch(`${ApiUrl}` + "packages/list/Coach?u_type=wl_admin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            packages: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getResellers = () => {
    if (this.props.role.id == 1) {
      fetch(`${ApiUrl}` + "resellers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            this.setState({
              resellers: response.data.records,
            });
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.setState({
        resellers: [],
      });
    }
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            roles: response.data.roles,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.user_id ? this.state.record.user_id : -1;
    }
    if (this.state.record.phone) {
      values.phone = this.state.record.phone;
    }
    values.action_by = 'Admin';

    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "edit/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          this.props.history.push("/users/admin/interpreter");
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
handleInputChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        [name]: value,
      },
    }));
  }
  render() {
    const paymentStyle = {
      visibility: this.state.payment_fields_visible,
      height: (this.state.payment_fields_visible == 'visible') ? 'auto' : '0',
      paddingTop: (this.state.payment_fields_visible == 'visible') ? 'auto' : '0',
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>

            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <div className="dialer">
          <Loader loaded={this.state.loaded} />
        </div>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">

                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">

                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="row">
                  <fieldset className="mb-3">
                    <legend>Personal Information</legend>
                       <Row>
                          <Col md={6}>
                            <div className="form-group">
                            <label>Name<span className="required">*</span></label>
                              <AvField
                                name="name"
                                value={this.state.record.name} 
                                className="form-control"
                                placeholder="Enter name"
                                type="text"
                                required
                              />
                            </div>
                          </Col>
                         
                          <Col md={6}>
                            <div className="form-group">
                              <label>Phone number<span className="required">*</span></label>
                               <div className="group-addnon">
                                  <div className="input-group">
                                    <PhoneInput
                                      country={'us'}
                                      value={this.state.record.phone}
                                      onChange={(phone) => this.handleInputChange({ target: { name: 'phone', value: phone } })}
                                      inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true,
                                        className: "form-control",
                                      }}
                                    />
                                  </div>
                                </div>
                            </div>
                          </Col>
                      </Row>
                  </fieldset>
                  <fieldset className="mb-3">
                    <legend>Login Details </legend>
                     <Row>
                        <Col md={6}>
                            <div className="form-group">
                              <label>Email<span className="required">*</span></label>
                                <div className="group-addnon">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="validationTooltipUsernamePrepend"
                                      >
                                        <i className="mdi mdi-email"></i> 
                                      </span>

                                    </div>
                                    <AvField
                                      name="email"
                                      value={this.state.record.email}
                                      className="form-control"
                                      placeholder="Enter email"
                                      type="email"
                                      required
                                    />

                                  </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label>Initial Password<span className="required">*</span>
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                       This is a one-time password that the interpreter will need to change upon their first login.
                                      </Tooltip>
                                    }
                                  >
                                   <i className='fas fa-info'></i>
                                </OverlayTrigger>
                            </label>
                            <AvField
                              name="password"
                              className="form-control"
                              placeholder={this.state.id == undefined ? "Enter Password" : "********"}
                              minLength={8}
                              errorMessage="Password must be at least 8 characters"
                              required={this.state.id == undefined ? true : false}
                              type="text"
                            />
                          </div>
                        </Col>
                      </Row>
                  </fieldset>
                  {this.state.page_name == "Create Interpreter" && (
                    <>
                
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="package_id"
                          value={this.state.record.package_id}
                          label={
                          <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="button-tooltip">
                                - Interpreters added by an admin will have a manual subscription.<br/>
                                - Our system will not charge a monthly subscription for interpreters added by an admin from this section.<br/>
                                - Interpreters who sign up through the front end will have a paid subscription.
                                </Tooltip>
                              }
                            >
                             <span>Package<span className="required">*</span> <i className='fas fa-info'></i></span>
                            </OverlayTrigger>
                          }
                          required
                        >
                          <option value={""}> Select Package </option>
                          <optgroup label="Monthly Packages">

                            {this.state.packages.map((row) => {
                              if (row.package_interval == "Monthly" && row.status == "Active") {
                                return (
                                  <option
                                    key={row.package_id}
                                    value={row.package_id}
                                  >

                                    {`${row.name} (${row.type} - ${row.price}) | ${row.package_interval}`}
                                  </option>
                                );
                              }
                            })}
                          </optgroup>
                          <optgroup label="Yearly Packages">
                            {this.state.packages.map((row) => {
                              if (row.package_interval == "Yearly" && row.status == "Active") {
                                return (
                                  <option
                                    key={row.package_id}
                                    value={row.package_id}
                                  >

                                    {`${row.name} (${row.type} - ${row.price}) | ${row.package_interval}`}
                                  </option>
                                );
                              }
                            })}
                          </optgroup>
                        </AvField>
                      </div>

                      <div className="mb-3 d-none">
                        <label>Payment Method</label>
                        <AvRadioGroup
                          value={this.state.record.payment_method}
                          inline
                          name="payment_method"
                          onChange={(e) =>
                            this.onChangePaymentAction(e.target.value)
                          }
                        >
                          <AvRadio label="Manual" value="manual" />
                          <AvRadio label="Paid" value="stripe" />
                        </AvRadioGroup>
                      </div>

                      {
                        this.state.record.payment_method == 'stripe' && (
                          <ModalBody className="row" style={paymentStyle}>
                            <div className="col-md-12 mb-3">
                              <AvField
                                className="select form-control"
                                type="select"
                                name="payment_type"
                                label="Payment Type"
                                value={this.state.record.payment_type}
                                required={this.state.isRequired}
                              >
                                <option value={""}> Select Payment Type </option>
                                <option value={"recurring"}> Recurring </option>
                                <option value={"one_time"}> One Time </option>

                              </AvField>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="control-label"> Card Number </label>
                              <AvField
                                name="cardnumber"
                                className="form-control"
                                type="text"
                                placeholder="Enter Card Number"
                                value={this.state.record.cardnumber}
                                required={this.state.isRequired}
                                maxLength={16}
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}

                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="control-label"> CVC</label>
                              <AvField
                                name="cvc"
                                className="form-control"
                                type="text"
                                placeholder="CVC"
                                value={this.state.record.cvc}
                                required={this.state.isRequired}
                                maxLength={4}
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <AvField
                                name="expirationdateMM"
                                label="MM"
                                className="form-control"
                                type="text"
                                placeholder="MM"
                                value={this.state.record.expirationdateMM}
                                required={this.state.isRequired}
                                maxLength={2}
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="expirationdateYYYY"
                                label="YYYY"
                                className="form-control"
                                type="text"
                                placeholder="YYYY"
                                value={this.state.record.expirationdateYYYY}
                                required={this.state.isRequired}
                                maxLength={4}
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}
                              />
                            </div>
                            <div className="col-md-12 mb-3">
                              <AvField
                                name="address_line1"
                                label="Billing Address"
                                className="form-control"
                                type="text"
                                placeholder="Billing Addresss"
                                value={this.state.record.address_line1}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="address_city"
                                label="Billing City"
                                className="form-control"
                                type="text"
                                placeholder="Billing City"
                                value={this.state.record.address_city}

                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="address_state"
                                label="Billing State"
                                className="form-control"
                                type="text"
                                placeholder="Billing State"
                                value={this.state.record.address_state}

                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="address_zip"
                                label="Billing Zip"
                                className="form-control"
                                type="text"
                                placeholder="Billing Zip"
                                value={this.state.record.address_zip}

                              />
                            </div>
                          </ModalBody>
                        )
                      }
                    </>
                  )}

                  <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="select"
                    id="s"
                    name="status"
                    label={
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="button-tooltip">
                            - Only active interpreters can log in to the system.<br/>
                            - Inactive interpreters cannot log in to the system, but their billing subscription will remain active on Stripe.
                          </Tooltip>
                        }
                      >
                        <span>Status <span className="required">*</span> <i className="fas fa-info"></i></span>
                      </OverlayTrigger>
                    }
                    required
                    value={this.state.record.status}
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </AvField>

                  </div>
                </ModalBody>
                <ModalFooter>
                  
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >

                    Save Changes
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
