import React, { useEffect, useState } from "react"


  const Incoming = ({ connection, state, displayMessage, setStatusOnReject}) => {
  const [showPage, setShowPage] = useState(false);

  const acceptConnection = () => {
    connection.accept();
    setShowPage(true);

  };
  const rejectConnection = () => {
    connection.reject();
    setStatusOnReject();
  };

  let params = Object.fromEntries(new URLSearchParams(connection.parameters.Params));
  params.From = connection.parameters.From;

  return (
    <div className="incoming-twilio-call">
      <>
      {state != "ON_CALL" ? (
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <h4 className="incomint-notify"> <i className="fas fa-phone"></i> Incoming call From  {connection.parameters.From}</h4>
        <div className="accept-rejact mb-5">
            <button className="accept" onClick={() => acceptConnection()} color="green"><i className="fas fa-phone"></i></button>
          <button className="rejact" onClick={rejectConnection} color="red"><i className="fas fa-phone"></i></button>
        </div>
      
      </div>
    ) : (
      <div className="d-flex mb-5">
        <h4 className="incomint-notify"> <i className="fas fa-phone"></i> {displayMessage}</h4>
      </div>
    )}
  </>
    </div>
  );
};

export default Incoming;
