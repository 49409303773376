var countries = [
    { id: 'usa', label: 'USA / Canada' , code: '+1' },
    { id: 'australia', label: 'Australia'  , code: '+98'},
    // { id: 'uk', label: 'United Kingdom' },
    // { id: 'newzealand', label: 'New Zealand' },
    // { id: 'netherlands', label: 'Netherlands' },
    // { id: 'southafrica', label: 'South Africa' },
    // { id: 'ireland', label: 'Ireland' },
    // { id: 'mexico', label: 'Mexico' },
    // { id: 'vanuatu', label: 'Vanuatu' },
    // { id: 'pakistan', label: 'Pakistan' },
    // { id: 'uae', label: 'UAE' },
];

export default countries;