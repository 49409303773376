import React, { Component, Fragment } from "react";
// import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Tabs,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
  CardText,
  Tablist,
  TabPanel,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import ProfileSettings from "../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ModalTitle } from "react-bootstrap";
import swal from 'sweetalert';
export default class EmailSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);

    this.state = {
      index: "1",
      tab: "1",
      prevtab: "1",
      webhookchangemodal: false,
      activeTabId: 0,
      id: obj.user_id,
      record: {},
      stripe: {},
      chargebee: {},
      authorize: {},

      page_name: this.props.match.params.id ? "Stripe Settings" : "Stripe Settings",
      setUpPermission: {
        permission: 0,
        remaining_coach: 0,
        packages: []
      }
    };
  }
  componentDidMount() {
    this.getData();
    this.getcurrentsetingdata();
  }

  toggle(index) {
    if (index == 1) {
      this.setState({
        tab: this.state.prevtab,
        index: index,
        // webhookchangemodal: !this.state.settingchangemodal,
      });
    } else if (index == 2) {
      this.setState({
        tab: index,
        index: index,
      });
    }

  }

 

  changesetting() {
    this.setState({
      tab: this.state.index,
      prevtab: this.state.index,
      webhookchangemodal: false,
    });
    this.updatesetting(this.state.index);
  }

  updatesetting = (id) => {
    fetch(`${ApiUrl}` + "update/user/paymentsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/paymentsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            stripe: response.data.record.stripe,
            chargebee: response.data.record.chargebee,
            authorize: response.data.record.authorize,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getcurrentsetingdata = () => {
    fetch(`${ApiUrl}` + "get/user/paymentsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            tab: response.data.record.payment_setting,
            prevtab: response.data.record.payment_setting,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmitstripe = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/stripe", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          swal({
            text: "Updated successfully.",
            icon: "success",
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitchargebee = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/chargebee", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitauthorize = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/authorize", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  submitSetupPrice = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/setup/fee", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col lg={12}>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              

              <TabContent activeTab={this.state.tab}>
                <TabPane tabId="1">

                  <Row>
                  <Col className="col-12">
                    <div className="alert alert-info">
                      <p>
                        <i className="fas fa-question"> </i> Help
                      </p>
                      <p>
                        If you have not setup your Stripe account
                        <span>
                          <a
                            className=""
                            href="https://dashboard.stripe.com/register"
                            target="_blank"
                          >
                            &nbsp;Click Here
                          </a>
                        </span>
                        &nbsp;to setup.
                      </p>

                      <p>
                        You can get your Stripe credentials by clicking on
                        Account Setting and then API Keys.
                      </p>

                      <p>
                        Add
                        <button
                          onClick={() =>
                            this.setState({
                              webhookchangemodal: true
                            })
                          }
                          className="btn btn-link"
                        >
                          Webhook URL
                        </button>
                        in Stripe under Developers Webhook and then
                        Add endpoint.
                      </p>

                      <p>
                        If you have not setup your Stripe client ID
                        <span>
                          &nbsp;
                          <a
                            className=""
                            href="https://dashboard.stripe.com/account/applications/settings"
                            target="_blank"
                          >
                            Click Here
                          </a>
                        </span>
                        &nbsp;to setup.
                      </p>
                    </div>
                  </Col>

                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitstripe}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody>
                            <div className="mb-3">
                              <AvField
                                name="secret_key"
                                label={<span>Secret Key<span className="required">*</span></span>}
                                type="password"
                                value={this.state.stripe.secret_key}
                                className="form-control"
                                placeholder="Secret Key"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="publishable_key"
                                label={<span>Publish Key<span className="required">*</span></span>}
                                type="password"
                                value={this.state.stripe.publishable_key}
                                className="form-control"
                                placeholder="Publish Key"
                                required
                              />
                            </div>
                            <div className="mb-3 d-none">
                              <AvField
                                name="client_id"
                                label="Client Id (Optional)"
                                value={this.state.stripe.client_id}
                                className="form-control"
                                placeholder="Client Id (Optional)"
                              />
                            </div>
                         
                          </ModalBody>
                          <ModalFooter>
                            
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                            Save Changes
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                    
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                 
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
        <Modal isOpen={this.state.webhookchangemodal} fade={false}>
          <ModalTitle>
            <h6 className="p-1">WebHook URL</h6>
          </ModalTitle>
          <ModalBody>
            <p> https://callcetner.com/webhook/stripe</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() =>
                this.setState({
                  webhookchangemodal: false,
                })
              }
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
