import React, { Component, Fragment } from "react";
import Loader from "react-loader";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Media,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import ProfileSettings from "../../Authentication/profile-sidebar";
import swal from 'sweetalert';
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import { uToken } from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { convert_cent_to_doller } from "../../../useToken";
import { toast, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import countries from "../../../common/data/countries";
export default class EmailSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      index: "1",
      tab: "1",
      prevtab: "1",
      settingchangemodal: false,
      addnewcardmodal: false,
      activeTabId: 0,
      id: obj.user_id,
      records: {},
      users: [],
      total_pages: 0,
      record: {},
      stripe: {},
      creditsstatus: {},
      chargebee: {},
      authorize: {},
      currentCountry: countries[0],
      countryListArray: [],
      allListArray: [],
      rowUpdate: {},
      consumptionList: {},
      credit_subscription: [],
      free_credits: [],
      freecredit_totalpage: 0,
      creditsublog_total_pages: 0,
      credit_packages: [],
      selected_package_detail: [],
      payment_display: "none",
      page_name: this.props.match.params.id ? "Credits" : "Credits",
      currentpackage: "",
      loaded: true,
      cureent_payment_method_id: 0,
      cureent_payment_method: "",
      card_details: [],
    };
    this.credityhistory_columns = [
      {
        text: "Log Id#",
        key: "id",
        sortable: false,
      },

      {
        text: "Summary",
        key: "details",
        sortable: false,
      },
      {
        text: "Type",
        key: "credit_type",
        sortable: false,
      },

      {
        text: "Total Used",
        key: "credits",
      },
      {
        text: "Date",
        key: "created_at",
      },
    ];
    this.freecredits_columns = [
      {
        text: "SR#",
        key: "sr",
        sortable: false,
      },

      {
        text: "Name",
        key: "name",
        sortable: true,
      },
      {
        text: "Email",
        key: "email",
        sortable: true,
      },

      {
        text: "Amount (¢)",
        key: "amount",
      },
      {
        text: "Action",
        key: "other_settings",
      },
      {
        text: "Date",
        key: "created_at",
      },
    ];
    this.creditsubscription_columns = [
      {
        text: "SR#",
        key: "sr",
        sortable: false,
      },

      {
        text: "Name",
        key: "name",
        sortable: true,
      },
      {
        text: "Email",
        key: "email",
        sortable: true,
      },

      {
        text: "Amount (¢)",
        key: "amount",
      },
      {
        text: "Action",
        key: "other_settings",
      },
      {
        text: "Date",
        key: "created_at",
      },
    ];
    
    this.config = {
      show_filter: false,
      show_pagination: true,
      bInfo: false,
    };
    this.history_config = {
      show_length_menu: false,
      show_filter: false,
      show_pagination: true,
      bInfo: false,
    };
    console.log(this.state.id, "debugging id ");
  }
  componentDidMount() {
    this.getcredditsstatus();
    this.getcreditpackages();
    this.getcarddetails();
  }
  getcarddetails = () => {
    fetch(`${ApiUrl}` + "user/getcarddetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            //customerID: response.customer_id,
            card_details: response.data.carddetails.data.data,
            //default_source:response.data.carddetails.customerdetails.default_source,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  addnewcard = async (event, values) => {
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "add/coach/newPaymentmethod", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          this.setState({
            addnewcardmodal: false,
            cureent_payment_method_id: "",
            cureent_payment_method: ""
          });
          this.getcarddetails();
          this.form && this.form.reset();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getcreditpackages = (queryString = "AccountCredit", data) => {
    fetch(`${ApiUrl}` + "packages/list/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            credit_packages: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.id ? this.state.id : -1;
    }

    if (this.state.currentpackage.package_id == '' || this.state.currentpackage.package_id == null) {
      swal({
        title: "Required Fields!",
        text: "Slect package from list",
        icon: "warning",
      });
      return;
    }

    if (this.state.currentpackage) {
      values.package_id = this.state.currentpackage.package_id
        ? this.state.currentpackage.package_id
        : -1;
    }
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "purchase/credit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          this.form && this.form.reset();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        

          this.getcredditsstatus();
          //this.getCreditRates();
          //this.getcredithistory();
          //this.getcreditsubscriptionlog();
          //this.getfreecreditslog();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getcreditsubscriptionlog = (queryString = "") => {
    fetch(`${ApiUrl}` + "get/creditsubscription/log" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            creditsublog_total_pages: response.data.total,
            credit_subscription: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

 


  getfreecreditslog = () => {
    fetch(`${ApiUrl}` + "get/freecredits/log", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            freecredit_totalpage: response.data.total,
            free_credits: response.data.records,
          });
          console.log(response, "Free Credits");
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getcredithistory = (queryString = "", data) => {
    let url = `${ApiUrl}` + "getcredit/history";
    fetch(`${url}` + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response, "here data");
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getcredithistory("?" + queryString);
  };

  packageChange = (current_package) => {
    this.setState({
      currentpackage: current_package,
    });

    fetch(`${ApiUrl}` + "get/edit/package", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: current_package.package_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            selected_package_detail: response.data.record,
            payment_display: "block",
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  countryChange = (country) => {
    this.setState({
      currentCountry: country,
    });

    var list = this.state.consumptionList["c"];

    var array = [];
    for (var key in list) {
      if (list[key][country.id] != undefined) {
        array.push({
          label: list[key].text,
          value: list[key][country.id],
          all: list[key]["all"],
          key: key,
        });
      }
    }

    var list2 = this.state.consumptionList["all"];
    var array2 = [];
    for (var key in list2) {
      array2.push({
        label: list2[key].text,
        value: "",
        all: list2[key]["all"],
        key: key,
      });
    }

    this.setState({
      countryListArray: array,
      allListArray: array2,
    });
  };
  getCreditRates = () => {
    fetch(`${ApiUrl}` + "credit/consumptionRates", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          consumptionList: response,
        });
        if (this.state.consumptionList) {
          this.countryChange(this.state.currentCountry);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  toggle(index) {
    this.setState({
      tab: index,
      prevtab: this.state.prevtab,
    });
  }

  getcredditsstatus = () => {
    fetch(`${ApiUrl}` + "get/creditstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            creditsstatus: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const paymentStyle = {
      visibility: this.state.payment_fields_visible,
      height: this.state.payment_fields_visible == "visible" ? "auto" : "0",
      paddingTop: this.state.payment_fields_visible == "visible" ? "auto" : "0",
    };
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>

        <Row>
          <Col lg={2} className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg={10}>
            <Row>
              <Col sm={12}>
                <div className="page-title-box">
                  <h4> {this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Card>
                  <CardBody>
                    <Media>
                      <div className=""></div>
                      <Media body className="align-self-center">
                        <div className="text-muted">
                          <Row>
                            <Col sm={6}>
                              <h6 className="m-0">
                                Consumed Credits: &nbsp;
                                {convert_cent_to_doller(this.state.creditsstatus.consume_credit)}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <h6 className="m-0">
                                Remaining Credits: &nbsp;
                                {convert_cent_to_doller(this.state.creditsstatus.available_credit)}
                              </h6>
                            </Col>
                          </Row>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-12">
                <Card className="mt-2 mb-2">
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 1 ? "active" : ""}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Buy Credits
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={this.state.tab == 2 ? "active" : ""}
                          onClick={() => {
                            this.toggle("2");this.getCreditRates();
                          }}
                        >
                          Rates
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 3 ? "active" : ""}
                          onClick={() => {
                            this.toggle("3");this.getcredithistory();
                          }}
                        >
                          Detail
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 4 ? "active" : ""}
                          onClick={() => {
                            this.toggle("4");this.getcreditsubscriptionlog();
                          }}
                        >
                          Purchased
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 5 ? "active" : ""}
                          onClick={() => {
                            this.toggle("5");this.getfreecreditslog(); 
                          }}
                        >
                          Free Credits
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                    <NavLink
                      className={this.state.tab == 6 ? "active" : ""}
                      onClick={() => {
                        this.toggle("6");
                      }}
                    >
                      Auto Renewal Package
                    </NavLink>
                  </NavItem> */}
                    </Nav>
                  </CardBody>
                </Card>
              </Col>

              <TabContent activeTab={this.state.tab}>
                <TabPane tabId="1">
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmit}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody>
                            <Row>
                              <Col className="col-9">
                                <Row className="mt-3">
                                  <Col className="col-12">
                                    <label className="control-label">
                                      Select Package
                                    </label>
                                    <div className="MainTZone">
                                      <Select
                                        value={this.state.currentpackage}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.package_id}
                                        isMulti={false}
                                        options={this.state.credit_packages}
                                        classNamePrefix="select2-selection"
                                        name="package_id"
                                        onChange={(e) => {
                                          this.packageChange(e);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col className="col-12 mb-3">
                                    <label className="control-label">
                                      Select Credit Card
                                    </label>

                                    <div className="card-list">
                                      <Select
                                        value={this.state.cureent_payment_method}
                                        getOptionLabel={(option) => '****' + option.last4 + ' - ' + option.exp_month + '/' + option.exp_year}
                                        getOptionValue={(option) => option.id}
                                        isMulti={false}
                                        options={this.state.card_details}
                                        classNamePrefix="select2-selection"
                                        name="card_ids"
                                        onChange={(e) => {
                                          this.setState({
                                            cureent_payment_method_id: e.id,
                                            cureent_payment_method: e,
                                          })
                                        }}
                                        required
                                      />
                                    </div>
                                    <AvInput type="hidden" name="card_id" value={this.state.cureent_payment_method_id} />

                                  </Col>
                                  <Col lg="12">
                                    <button onClick={() =>
                                      this.setState({
                                        addnewcardmodal: true,
                                      })
                                    } type="button" className="btn btn-link p0"><i className="fas fa-plus"></i> Add New Payment Method</button>
                                  </Col>
                                </Row>
                                {/* <Row className="mt-3">
                                  <Col className="col-6">
                                    <AvField
                                      name="cardnumber"
                                      label="Card Number"
                                      className="form-control"
                                      placeholder="Enter Card Number"
                                      value={this.state.record.cardnumber}
                                      required={true}
                                      maxLength={16}
                                      validate={{
                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                      }}
                                    />
                                  </Col>
                                  <Col className="col-6">
                                    <label className="control-label"> CVC</label>
                                    <AvField
                                      name="cvc"
                                      className="form-control"
                                      placeholder="CVC"
                                      value={this.state.record.cvc}
                                      required={true}
                                      maxLength={4}
                                      validate={{
                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                      }}
                                    />
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col className="col-6">
                                    <AvField
                                      name="expirationdateMM"
                                      label="MM"
                                      className="form-control"
                                      placeholder="MM"
                                      value={this.state.record.expirationdateMM}
                                      required={true}
                                      maxLength={2}
                                      validate={{
                                        // required: { value: true, errorMessage: 'This field is invalid' },
                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                                      }}
                                    />
                                  </Col>
                                  <Col className="col-6">
                                    <AvField
                                      name="expirationdateYYYY"
                                      label="YYYY"
                                      className="form-control"
                                      placeholder="YYYY"
                                      value={this.state.record.expirationdateYYYY}
                                      required={true}
                                      maxLength={4}
                                      validate={{
                                        // required: { value: true, errorMessage: 'This field is invalid' },
                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                      }}
                                    />
                                  </Col>
                                </Row> */}

                              </Col>
                              <Col className="col-3 mt-5 ml-1">
                                <div
                                  className="package_details"
                                  style={{
                                    display: this.state.payment_display,
                                  }}
                                >
                                  <h6> Package Detail</h6>
                                  Package Credit:
                                  {
                                    this.state.selected_package_detail
                                      .credit_amount
                                  }
                                  (¢)
                                  <br></br>
                                  Package Price: $
                                  {
                                    this.state.selected_package_detail
                                      .price
                                  }
                                </div>
                              </Col>
                            </Row>
 
                          </ModalBody>
                          <Loader
                            loaded={this.state.loaded}
                            className="spinner"
                          >
                            <ModalFooter>
                              <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                              >
                                Cancel
                              </Button>
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </Loader>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={"2"}>
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <Col className="col-12">
                          <Row className="align-items-center">
                            <Col md={1}>
                              <label>Country:</label>
                            </Col>
                            <Col md={7}>
                              <div className="MainTZone">
                                <Select
                                  value={this.state.currentCountry}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.id}
                                  isMulti={false}
                                  options={countries}
                                  classNamePrefix="select2-selection"
                                  name="time_zone"
                                  onChange={(e) => {
                                    this.countryChange(e);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={2}></Col>

                            <Col md={12}>
                              <div className="table-responsive mt-5">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Type</th>
                                      <th>Amount</th>
                                      <th>For All</th>
                                      <th className="hidden-xs"> Action </th>
                                    </tr>
                                    <tr>
                                      <th colSpan={3}>
                                        Prices For country (
                                        {this.state.currentCountry.id})
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.countryListArray.map(
                                      (list, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{list.label}</td>
                                            <td>{convert_cent_to_doller(list.value)}</td>
                                            <td>{convert_cent_to_doller(list.all)}</td>
                                            <td className="hidden-xs"></td>
                                          </tr>
                                        );
                                      }
                                    )}
                                    <tr>
                                      <th colSpan={3}>For All Countries</th>
                                    </tr>

                                    {this.state.allListArray.map((list, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>{list.label}</td>
                                          <td>{convert_cent_to_doller(list.value)}</td>
                                          <td>{convert_cent_to_doller(list.all)}</td>
                                          <td className="hidden-xs"></td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane tabId={"3"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <CardTitle className="h4"></CardTitle>

                          <ReactDatatable
                            config={this.history_config}
                            records={this.state.records}
                            columns={this.credityhistory_columns}
                            dynamic={false}
                            // total_record={this.state.total_pages}
                            // onChange={this.tableChangeHandler}
                          />
                          <div className="page-title-box text-align-right">
                            <Link
                              type="button"
                              color="info"
                              className="btn btn-info"
                              to={"/report/credithistory"}
                            >
                              <i className="mdi mdi-arrow align-items-right"></i>
                              View All
                            </Link>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"4"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <CardTitle className="h4"></CardTitle>

                          <ReactDatatable
                            config={this.config}
                            records={this.state.credit_subscription}
                            columns={this.creditsubscription_columns}
                            dynamic={false}
                            // total_record={this.state.creditsublog_total_pages}
                            // onChange={this.tableChangeHandler}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"5"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <CardTitle className="h4"></CardTitle>

                          <ReactDatatable
                            config={this.config}
                            records={this.state.free_credits}
                            columns={this.freecredits_columns}
                            dynamic={false}
                            // total_record={this.state.freecredit_totalpage}
                            // onChange={this.tableChangeHandler}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
          <Modal
          isOpen={this.state.addnewcardmodal}
          role="dialog"
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            this.setState({
              addnewcardmodal: false,
            });
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                this.setState({
                  addnewcardmodal: false,
                });
              }}
              className="text-transform-capitalize"
            >
              Add New Card
            </ModalHeader>
            <AvForm
              onValidSubmit={this.addnewcard}
              className="needs-validation"
            >
              <ModalBody>
                <Row className="mt-3">
                  <Col className="col-6">
                    <label className="control-label">Card Number</label>
                    <AvField
                      name="cardnumber"
                      className="form-control"
                      type="text"
                      placeholder="Enter Card Number"
                      required
                      maxLength={16}
                      validate={{
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </Col>
                  <Col className="col-6">
                    <label className="control-label"> CVC</label>
                    <AvField
                      name="cvc"
                      className="form-control"
                      type="text"
                      placeholder="CVC"
                      required
                      maxLength={4}
                      validate={{
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="col-6">
                    <AvField
                      name="expirationdateMM"
                      label="MM"
                      className="form-control"
                      type="text"
                      placeholder="MM"
                      maxLength={2}
                      required
                      validate={{
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </Col>
                  <Col className="col-6">
                    <AvField
                      name="expirationdateYYYY"
                      label="YYYY"
                      className="form-control"
                      type="text"
                      placeholder="YYYY"
                      required
                      maxLength={4}
                      validate={{
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <Loader loaded={this.state.loaded} className="spinner">
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        addnewcardmodal: false,
                      });
                    }}
                  >
                    Close
                  </Button>
                  <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                </ModalFooter>
              </Loader>
            </AvForm>
          </div>
        </Modal>
        </Row>
      </React.Fragment>
    );
  }
}
