import React, { useEffect, useState } from "react"
import { uToken } from "../../useToken";
import { ApiUrl } from "../../config";
import { toast, Flip } from "react-toastify"
import Incoming from "./Incoming";
import states from "./states";
import OnCall from "./OnCall";
import { Device } from "twilio-client";

const Incall = props => {
    const [conn, setConn] = useState(false);
    const [device, setDevice] = useState(true);
    const [page2Class, setPage2Class] = useState('');
    const [displayMessage, setDisplayMessage] = useState('');
    const [showSlider, setShowSlider] = useState(false);
    const [state, setStateVal] = useState("Connecting");
    const [modal, setModal] = useState(false);
    const [isDialPadOpen, setDialPadOpen] = useState(false);
     const [token, setToken] = useState('');
     const [agent, setAgent] = useState('');

    useEffect(() => {
        fetch(`${ApiUrl}` + "voice/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setAgent(response.data.agent);
                    setToken(response.data.token);
                     const deviceInstance = new Device();
                    deviceInstance.setup(response.data.token, { debug: true });

                    deviceInstance.on("ready", () => {
                        setDevice(deviceInstance);
                        
                    });

                    deviceInstance.on("connect", (connection) => {
                        setConn(connection);
                        setStateVal("ON_CALL");
                        setDisplayMessage("Connected with phone number " + connection.parameters.From);
                        console.log("Connected paramsters - connect", connection.parameters);
                    });

                    deviceInstance.on("disconnect", () => {
                        setConn(null);
                        setDisplayMessage("Incoming call disconnected");
                        setStatusOnReject();
                    });

                    deviceInstance.on("incoming", (connection) => {
                        setConn(connection);
                        setStateVal("Incoming");
                        connection.on("reject", () => {
                            setConn(null);
                            setDisplayMessage("Incoming call rejected");
                        });
                    });

                    deviceInstance.on("cancel", () => {
                        setConn(null);
                        setDisplayMessage("Incoming call canceled");
                        setStatusOnReject();
                        setDevice(null);
                    });

                    return () => {
                        deviceInstance.destroy();
                        setDevice(null);
                    };
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);



    const sliderOut = () => {
        setPage2Class('slide-out2');
        setShowSlider(false);
        setConn(null);
        window.location.reload(false);
    }
    const setStatusOnReject = () => {
        setStateVal("ON_CALL");
    }
    

    const handleHangup = () => {
        device.disconnectAll();
    };

    const handleDialPad = () => {
        setModal(!modal); // Toggle the modal on button click
    };

    let render;
    if (conn) {
        if (state === states.READY || state === states.CONNECTING || state === states.INCOMING) {
            render = (
                <Incoming
                    state={state}
                    displayMessage={displayMessage}
                    connection={conn}
                    setStatusOnReject={setStatusOnReject}
                />
            );
        } else if (state === 'ON_CALL') {
            render = <OnCall handleHangup={handleHangup}  agentName={agent.agent_name}  agentNumber={agent.agent_phone}  connection={conn} handleDialPad={handleDialPad} />;
        }
    }

    return (
        <>
            {render}
        </>
    );
};
export default Incall
