import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { Link, Redirect, useLocation } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { toast, ToastContainer, Flip } from 'react-toastify';
import Helmet from "react-helmet"
import { ApiUrl, ProductName, logoLightPng } from '../../config';
import useToken, { bcrypt } from '../../useToken';
import { useSwitchUser } from '../../useToken';
import Loader from "react-loader";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login = props => {
  let query = useQuery();
  const { setToken } = useToken();
  const { setSwitchUser } = useSwitchUser();
  const [msg, setMsg] = useState('');
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (query.get("msg")) {
      setMsg(query.get("msg"))
    }
    document.body.classList.add('bgCustomCls');
  }, []);


  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    setLoaded(false);
    fetch(`${ApiUrl}` + 'authenticate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === true) {
            setToken(res.data.user);
            setSwitchUser([]);
            window.location.href = '/dashboard';
        } else {
          if (res.data) {
            setLoaded(true);
            toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          } else {
            setLoaded(true);
            toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }


        }

      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  console.log(props, 'login')
  return (
    <React.Fragment>
      <Helmet>
        <title>Login </title>
      </Helmet>
      <div className="account-pages my-5 pt-sm-5" style={{background: "#051d40"}}>
        <Container>
          <ToastContainer />
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={7}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-1">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoLightPng} alt="" height="50" className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    {/* <h4 className="text-muted font-size-18 mb-1 text-center">Welcome Back !</h4>
                    <p className="text-muted text-center">Sign in to continue to {ProductName}.</p> */}
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {
                        msg && msg != '' && (
                          <Alert color="success">{msg}</Alert>

                        )
                      }

                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Enter username or email"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >


                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                        </div>
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="/signup/select/package"
                    className="text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default Login;