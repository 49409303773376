import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";

import { withRouter } from "react-router-dom";

import Avatar from "react-avatar";
import Breadcrumb from "../../components/Common/Breadcrumb";

import Select from "react-select";
import Avilability from "./Avilability"
import Skills from "./Skills"
import {
  Asia,
  Africa,
  America,
  Antartica,
  Atlantic,
  Australia,
  Europe,
  Indian,
  Pacific,
} from "../../common/data/timezones";
import { uInfo, uToken } from "../../useToken";
import { ApiUrl, AttachementsUrl, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import countries from "../../common/data/countries";
import swal from 'sweetalert';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const UserProfile = (props) => {
  const [timeZone_data, setnewzone] = useState(Asia);
  const [user, setUser] = useState("");
  const [timezone, setTimeZone] = useState("");
  const [maintimezone, setMainTimeZone] = useState("");
  const [tab, setTab] = useState("1");
  const [numbers, setNumber] = useState([]);
  const [country_code, setCountry_code] = useState(0);
  const [path, setPath] = useState('');
 
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));

      setUser(obj);

      if (uInfo().image == '' || uInfo().image == null) {
        setPath(`${AttachementsUrl}default/images/avatar_image.jpg`);
      } else {
        setPath(`${AttachementsUrl}user_${uInfo().user_id}/logo.png`);
      }
      let data = obj.time_zone ? obj.time_zone.split("/") : [];
      setTimeZone({ id: obj.time_zone || "", label: data[1] || "" });
      setMainTimeZone({ id: data[0] || "", label: data[0] || "" });

    }
  }, [!user]);


  useEffect(() => {
    if (uInfo().type == 'Coach' || uInfo().type == 'CoachPartner') {
      getPhoneNumbers();
    }
  }, []);

  function toggle(index) {
    setTab(index);
  }

  function settimezone(e) {
    console.log(e, "DEBUG");
    setMainTimeZone(e);

    if (e.label == "Australia") {
      setnewzone(Australia);
    } else if (e.label == "America") {
      setnewzone(America);
    } else if (e.label == "Asia") {
      setnewzone(Asia);
    } else if (e.label == "Europe") {
      setnewzone(Europe);
    } else if (e.label == "Indian") {
      setnewzone(Indian);
    }

    // console.log(timeZone_data,'Zone Change');
  }


  const getPhoneNumbers = () => {
    fetch(`${ApiUrl}` + "user/form/data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setNumber(response.data.phones);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const breadcrumbItems = [{ title: "Profile", link: "#" }];
  const maintimezones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
  ];



  function updateUserPhoneSetting(event, values) {
    fetch(`${ApiUrl}` + "update/user/phone/setting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            direct_number: values.direct_number,
            inbound_call_option: values.inbound_call_option,
            outbound_phone: values.outbound_phone,
            forward_number: values.forward_number,
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          setUser(authUser);
          swal({
            text: "Updated successfully.",
            icon: "success",
          });
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' }); 

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function handleValidSubmitpassword(event, values) {
    fetch(`${ApiUrl}` + "change/password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' }); 
           swal({
            text: "Updated successfully.",
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function handleValidSubmit(event, values) {
    values.phone = user.phone;
    fetch(`${ApiUrl}` + "update/profile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            name: data.data.name,
            email: data.data.email,
            time_zone: data.data.time_zone,
            zip: data.data.zip,
            phone: data.data.phone,
            state: data.data.state,
            address: data.data.address,
            city: data.data.city,
            country_code: data.data.country_code,
            company_name: data.data.company_name,
            website: data.data.website,
            industry: data.data.industry,
            employees: data.data.employees,
            year_founded: data.data.year_founded,
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          setUser(authUser);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
           swal({
            text: "Updated successfully.",
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function updateUserFromEmailSetting(event, values) {
    values.setting = 'email';
    values.name = user.name;
    values.email = user.email;
    fetch(`${ApiUrl}` + "update/profile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            from_email: data.data.from_email,
            from_name: data.data.from_name,
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          setUser(authUser);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }


  const userProfileImage = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }


    fetch(`${ApiUrl}` + "update/profile/image", {
      method: "POST",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            image: 'profile.png',
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          setUser(authUser);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: "Updated successfully.",
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const changeHandler = (e) => {
    if (e.target.files[0].type.match('image.*')) {
      const preview = document.querySelector('#preview');
      var reader = new FileReader();
      reader.onload = () => {
        preview.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);
      const fileChosen = document.getElementById('file-chosen');

      fileChosen.textContent = e.target.files[0].name
    } else {
      toast('Olny image allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

    }
  };
 const handleInputChange = (e) => {
  const { name, value } = e.target; 
  setUser((prevUser) => ({
    ...prevUser,
    [name]: value,
  }));
};


  return (
    <React.Fragment>
      <Row>

        {
          uInfo().type == 'Client' && (
            <Col lg="12">
              <button onClick={() => props.history.goBack()} className="btn btn-secondary pull-right mb-3">
                Back
              </button>
            </Col>
          )
        }
       
        <Col lg={'12'}>
          <Row>
            <Helmet>
              <title> Profile </title>
            </Helmet>

            <Col lg="12">
              <Card className="mb-2">
                <CardBody className="p-2">
                  <Nav tabs className="border-0 pt-0 profile-tabs">
                    <NavItem>
                      <NavLink
                        className={tab == 1 ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Edit Profile
                      </NavLink>
                    </NavItem>
                    {
                      (uInfo().type == 'Coach') && (
                        <NavItem className="d-none">
                          <NavLink
                            className={tab == 3 ? "active" : ""}
                            onClick={() => {
                              toggle("3");
                            }}
                          >
                            Personal Phone Settings
                          </NavLink>
                        </NavItem>
                      )
                    }
                   
                    
                  <NavItem>
                    <NavLink
                      className={tab === 5 ? "active" : ""}
                      onClick={() => {
                        toggle("5");
                      }}
                    >
                      {uInfo().type === 'WlAdmin' ? "Website Logo" : "Profile Image"}
                    </NavLink>
                  </NavItem>
                   {
                      (uInfo().type == 'Coach') && (
                        <NavItem>
                          <NavLink
                            className={tab == 4 ? "active" : ""}
                            onClick={() => {
                              toggle("4");
                            }}
                          >
                           Time Availability Settings
                          </NavLink>
                        </NavItem>
                      )
                    }
                     {
                      (uInfo().type == 'Coach') && (
                        <NavItem>
                          <NavLink
                            className={tab == 6 ? "active" : ""}
                            onClick={() => {
                              toggle("6");
                            }}
                          >
                          Manage Skills
                          </NavLink>
                        </NavItem>
                      )
                    }
                  <NavItem>
                    <NavLink
                      className={tab == 2 ? "active" : ""}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Change Password
                    </NavLink>
                  </NavItem>
                  </Nav>
                </CardBody>
              </Card>
              <TabContent activeTab={tab}>
                <TabPane tabId="1">
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(e, v);
                            }}
                          >
                           
                            <fieldset>
                              <legend>Personal Information</legend>
                               <Row>
                              <Col md={6}>
                                <div className="form-group mb-3">
                                  <AvField
                                    label={<span>Name<span className="required">*</span></span>}
                                    name="name"
                                    value={user.name} 
                                    className="form-control"
                                    placeholder="Enter name"
                                    type="text"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Email<span className="required">*</span></label>
                                    <div className="group-addnon">
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="validationTooltipUsernamePrepend"
                                          >
                                            <i className="mdi mdi-email"></i> 
                                          </span>

                                        </div>
                                        <AvField
                                          name="email"
                                          value={user.email}
                                          className="form-control"
                                          placeholder="Enter email"
                                          type="email"
                                          required
                                        />

                                      </div>
                                    </div>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Phone number<span className="required">*</span></label>
                                   <div className="group-addnon">
                                      <div className="input-group">
                                        <PhoneInput
                                          country={'us'}
                                          value={user.phone}
                                          onChange={(phone) => handleInputChange({ target: { name: 'phone', value: phone } })}
                                          inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true,
                                            className: "form-control",
                                          }}
                                        />
                                      </div>
                                    </div>
                                </div>
                              </Col>
                              </Row>
                            </fieldset>

                            <fieldset className="mt-5">
                              <legend>Company Info</legend>
                               <Row>
                                  <Col md={6}>
                                    <div className="form-group">
                                    <label>Company Name<span className="required"></span></label>
                                      <AvField
                                        name="company_name"
                                        label=""
                                        value={user.company_name}
                                        className="form-control"
                                        placeholder="Enter company name"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="form-group">
                                      <label>Website<span className="required"></span></label>
                                      <AvField
                                        name="website"
                                        label=""
                                        value={user.website}
                                        className="form-control"
                                        placeholder="Enter website"
                                        type="url"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="form-group">
                                      <label>Industry<span className="required"></span></label>
                                      <AvField
                                        name="industry"
                                        label=""
                                        value={user.industry}
                                        className="form-control"
                                        placeholder="Enter industry name"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                   <Col md={6}>
                                    <div className="form-group">
                                      <label>Employees<span className="required"></span></label>
                                      <AvField
                                        name="employees"
                                        label=""
                                        value={user.employees}
                                        className="form-control"
                                        placeholder="Enter industry name"
                                        type="number"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="form-group">
                                      <label>Year Founded<span className="required"></span></label>
                                      <AvField
                                        name="year_founded"
                                        label=""
                                        value={user.year_founded}
                                        className="form-control"
                                        placeholder="Enter founded year"
                                        type="number"
                                      />
                                    </div>
                                  </Col>
                              </Row>
                            </fieldset>
                           
                            <fieldset className="mt-5">
                              <legend>Location</legend>
                              <Row>
                                <Col md={12}>
                                  <div className="form-group">
                                   <label>Address<span className="required"></span></label>
                                    <AvField
                                      name="address"
                                      value={user.address}
                                      className="form-control"
                                      placeholder="Enter Address"
                                      type="textarea"
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="form-group">
                                  <label>City<span className="required"></span></label>
                                    <AvField
                                      name="city"
                                      value={user.city}
                                      className="form-control"
                                      placeholder="Enter city"
                                      type="text"
                                    />
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="form-group">
                                  <label>State<span className="required"></span></label>
                                    <AvField
                                      name="state"
                                      value={user.state}
                                      className="form-control"
                                      placeholder="Enter state"
                                      type="text"
                                    />
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="form-group">
                                    <label>Zip<span className="required"></span></label>
                                    <AvField
                                      name="zip"
                                      value={user.zip}
                                      className="form-control"
                                      placeholder="Enter zip"
                                      type="text"
                                      validate={{
                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                      }} />
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <label className="control-label">Country<span className="required"></span></label>
                                  <div className="TimeZoneMain">
                                    <Select
                                      getOptionLabel={(option) =>
                                        option.label + " (" + option.code + ")"
                                      }
                                      getOptionValue={(option) => option.code}
                                      value={countries.filter(
                                        ({ code }) => code === user.country_code
                                      )}
                                      isMulti={false}
                                      options={countries}
                                      classNamePrefix="select2-selection"
                                      name="country_id"
                                      onChange={(e) => {
                                        setUser(user => ({
                                          ...user,
                                          country_code: e.code
                                        }))
                                      }}
                                    />
                                  </div>
                                  <AvField
                                    type="hidden"
                                    name="country_code"
                                    value={user.country_code}
                                  />
                                </Col>
                              </Row>
                            </fieldset>
                             <fieldset className="mt-5">
                              <legend>Time Zone</legend>
                              <Row>
                                <Col md={6}>
                                  <AvGroup>
                                    <label>Main Time Zone<span className="required">*</span></label>
                                    <div className="TimeZoneMain MainTime">
                                      <Select
                                        value={maintimezone}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.id}
                                        isMulti={false}
                                        options={maintimezones}
                                        classNamePrefix="select2-selection"
                                        className={`${maintimezone != ""
                                          ? "is-touched is-dirty av-valid"
                                          : "is-touched is-pristine av-invalid is-invalid"
                                          }`}
                                        name="time_zone"
                                        onChange={(e) => {
                                          settimezone(e);
                                        }}
                                      />
                                    </div>
                                    <AvInput
                                      type="hidden"
                                      required
                                      name="time_zone_1"
                                      value={maintimezone.id}
                                    />
                                    <AvFeedback> This is required * </AvFeedback>
                                  </AvGroup>
                                </Col>
                                <Col md={6}>
                                  <AvGroup>
                                    <label>Sub Time Zone<span className="required">*</span></label>
                                    <div className="TimeZoneMain">
                                      <Select
                                        value={timezone}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.id}
                                        isMulti={false}
                                        options={timeZone_data}
                                        classNamePrefix="select2-selection"
                                        className={`${timezone != ""
                                          ? "is-touched is-dirty av-valid"
                                          : "is-touched is-pristine av-invalid is-invalid"
                                          }`}
                                        name="time_zone1"
                                        onChange={(e) => {
                                          setTimeZone(e);
                                        }}
                                      />
                                    </div>
                                    <AvInput
                                      type="hidden"
                                      required
                                      name="time_zone"
                                      value={timezone.id}
                                    />
                                    <AvFeedback> This is required * </AvFeedback>
                                  </AvGroup>
                                </Col>
                              </Row>
                            </fieldset>
                            <Row>
                              <Col md={12}>
                                <Button type="submit" className="mt-5" color="secondary">
                                  Save Changes
                                </Button>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"2"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmitpassword(e, v);
                            }}
                          >
                            <div className="form-group mb-3">
                              <label>Old password<span className="required">*</span></label>
                              <AvField
                                name="current_password"
                                className="form-control"
                                placeholder="Enter old password"
                                type="password"
                                required
                              />
                            </div>
                            <div className="form-group mb-3">
                              <label>New password<span className="required">*</span></label>
                              <AvField
                                name="new_password"
                                className="form-control"
                                placeholder="Enter new password"
                                type="password"
                                required
                              />
                            </div>
                           
                            <div className="mt-4">
                              <Button type="submit" color="secondary">
                                Save Changes
                              </Button>
                            </div>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"3"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              updateUserPhoneSetting(e, v);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="direct_number"
                                  label="Direct number"
                                  className="form-control"
                                  type="select"
                                  required
                                  value={user.direct_number}
                                >
                                  <option value="">Select Number</option>
                                  {
                                    numbers.map((row, i) => {
                                      return (
                                        (row.is_locked == 'No' || row.phone_number == user.direct_number) && (
                                          <option value={row.phone_number}>{row.phone_number}</option>
                                        )
                                      )
                                    })
                                  }
                                </AvField>
                              </div>
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="outbound_phone"
                                  label="Default outbound phone"
                                  className="form-control"
                                  type="select"
                                  required
                                  value={user.outbound_phone}
                                >
                                  <option value="">Select Number</option>
                                  {
                                    numbers.map((row, i) => {
                                      return <option value={row.phone_number}>{row.phone_number}</option>
                                    })
                                  }
                                </AvField>
                              </div>
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="inbound_call_option"
                                  label="Inbound call settings"
                                  className="form-control"
                                  placeholder="Enter Direct Number"
                                  type="select"
                                  required
                                  value={user.inbound_call_option}
                                >
                                  <option value="0">None</option>
                                  <option value="1" selected="selected">Ring browser only</option>
                                  <option value="2">Ring Phone only</option>
                                  {/* <option value="5">Ring Phone only (Whisper)</option> */}
                                  <option value="3">Ring browser and forward calls simultaneously ** Voicemail disable</option>
                                  {/* <option value="6">Ring browser and forward calls simultaneously (Whisper) ** Voicemail disable</option> */}
                                </AvField>
                              </div>

                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="forward_number"
                                  label="Forward number"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter forward number"
                                  required
                                  value={user.forward_number}
                                />
                              </div>
                            </div>

                            <div className="mt-4">
                              <Button type="submit" color="danger">
                                Save Changes
                              </Button>
                            </div>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"4"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <Avilability />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={"5"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <form onSubmit={userProfileImage} method="post"
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="m-1 ImagePreview">
                                  <img id="preview" src={path} height="200" alt="Image preview" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <div className="mb-3 HeadshotImg">
                                  <label htmlFor="actual-btn"><span>Upload Headshot</span></label>
                                  <span className="pl-5" id="file-chosen">No file chosen</span>
                                  <input
                                    name="image"
                                    label="Image"
                                    accept="image/*"
                                    className="form-control"
                                    type="file"
                                    id="actual-btn"
                                    onChange={(e) => changeHandler(e)}
                                    hidden
                                    required
                                  />
                                </div>
                                <button
                                  className="btn btn-primary waves-effect waves-light btn-block"
                                  type="submit"
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"6"}>
                 <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <Skills />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserProfile;
