import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Table } from "reactstrap";


import EmailSent from "./email-sent";


import RecentActivity from "./recent-activity";
import YearlySales from "./yearly-sales";
import LoginHistory from "./login-history";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";

import Helmet from "react-helmet";
import { bcrypt, uRole, uToken } from "../../useToken";
import { ApiUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";

//Import Action to copy breadcrumb items from local state to redux state
// import { setBreadcrumbItems } from "../../store/actions";

const Admin = (props) => {
  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" },
  ];

  const [transactionsHistory, setTransactionsHistory] = useState([]);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const [recentactivity, setrecenthistory] = useState([]);

  useEffect(() => {
    // props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
    getData();
  }, []);

  const getData = () => {
    fetch(`${ApiUrl}` + "dashboard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSubscriptionHistory(response.data.subscriptionHistory);
          setTransactionsHistory(response.data.transactionsHistory);
          setrecenthistory(response.data.recentactivity);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.Fragment>
      <Row>
        <LoginHistory />
      </Row>

      <Row>
        <Col xl="8">
          <EmailSent />
        </Col>
        {/* <Col xl="4">
          <YearlySales />
        </Col> */}
        <Col xl="4">
          <YearlySales />
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default Admin;
