import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from 'sweetalert';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader

} from "reactstrap";
import Select from "react-select";

import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
const UiDropdown = (props) => {
  useEffect(() => { });
  const [subscriptionid, setsubscriptionid] = useState(props.props);
  const [userid, setuserid] = useState(props.userid);
  const [record, setRecord] = useState(props.record);
  const [btnInfo1, setBtnInfo1] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deletealert, setdeletealert] = useState(false);
  const [status, setstatus] = useState(props.status);
  const [confirmmodal, setconfirmmodal] = useState(false);
  const [modal, setModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);

  const [toggleSwitch, settoggleSwitch] = useState(false);
  const [toggleSwitch1, settoggleSwitch1] = useState(false);

  const history = useHistory();
  const cancelsubscription = () => {
    fetch(`${ApiUrl}` + "cancel/user/subscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ user_id: props.userid }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          props.trigger_get_user();
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };
  const canceleuser = () => {
     const msg = uInfo().type === 'Coach' 
                        ? "Are you sure you want to cancel this client's account? This action will also cancel any recurring subscriptions associated with the client and release the assigned phone numbers." 
                        : "You want to cancel this user interpreter. Doing so will cancel all of this interpreter's clients. In addition, recurring subscriptions will be permanently removed."
    swal({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((isCancel) => {
        if (isCancel) {
          swal('"Enter keyword "CANCEL" to cancel this user', {
            content: "input",
            buttons: true,
            dangerMode: true,
          })
            .then((value) => {
              if (value.toLowerCase() == 'cancel') {
                cancelsubscription();
              } else {
                //swal("Sorry you entered keyword is not valid.", "success");
                swal("Sorry you entered keyword is not valid.", {
                  icon: "error",
                });
              }
            });
        }
      })

  }


  const loginAsSubCoach = () => {
    swal({
      title: "Are you sure?",
      text: "You want to login this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isDelete) => {
      if (isDelete) {
        fetch(`${ApiUrl}` + "login/sub/coach", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ user_id: props.userid, to_user_id: uInfo().staff_user_id > 0 ? uInfo().staff_user_id : uInfo().user_id }),
        })
          .then((response) => response.json())
          .then((response) => {

            if (response.status == true) {
              toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              props.loginAsSubCoach(response.data.user, response.data.users);
            } else {
              toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  const deleteuser = () => {
    swal({
      title: "Are you sure?",
      text: "You want to want to permanently delete this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isDelete) => {
      if (isDelete) {
        fetch(`${ApiUrl}` + "delete/user", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ id: props.userid }),
        })
          .then((response) => response.json())
          .then((response) => {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
            props.trigger_get_user();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();

    submitData.append('user_id', record.id);
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }


    fetch(`${ApiUrl}` + "manual/verfication", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          props.trigger_get_user();
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

 
  return (
    <React.Fragment>
     
          {status != "Canceled" ? (
            <>
              <Link
                  className="btn btn-primary btn-sm mb-1  mr-5"
                  onClick={() => {
                      const path = uInfo().type === 'Coach' 
                        ? `client/edit/${bcrypt(userid)}` 
                        : `interpreter/edit/${bcrypt(userid)}`;
                      history.push(path);
                    }}
                >
                  Edit
              </Link>
             
              
              {record.is_approved != "Yes" && (
               <Link
                  className="btn btn-primary btn-sm mb-1  mr-5"
                   onClick={() => {
                        props.changeApprovalUser(record)
                   }}
                >
                  Approve User
              </Link>
              )}
              <Link
                className="btn btn-danger mb-1  btn-sm"
                onClick={() => {
                      canceleuser()
                    }}
              >
               Cancel
              </Link>

               
            </>
          ) : (
             <Link
                className="btn btn-danger mb-1  btn-sm"
                onClick={() => {
                      deleteuser()
                }}
              >
               Delete
              </Link>
          )}

             
    </React.Fragment>
  );
};

export default UiDropdown;
