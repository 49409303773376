import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class UserOperation extends Component {
  constructor(props) {
    super(props);
    //console.log(dcrypt(this.props.match.params.id), 'debug');
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {},
      page_name: this.props.match.params.id
        ? "Edit Team"
        : "Create Team",
      agents: [],
    };
    // console.log(this.state.page_name, "debug");
  }

  componentDidMount() {
    this.getData();
    this.getAgents("", "");
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/team", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedAgent: response.data.record.agents,
            agent_id: response.data.record.agent_ids,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getAgents = (queryString = "?all=1", data) => {
    fetch(`${ApiUrl}` + "coachpartners" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            agents: response.data.records,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }

    fetch(`${ApiUrl}` + "edit/team", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          this.props.history.goBack();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
handleAgent = (selectedAgent) => {
    var filter = [];
    if (selectedAgent) {
      selectedAgent.map((fil) => {
        filter.push(fil.user_id);
      });
    }

    this.setState({
      agent_id: filter.toString(),
      selectedAgent: selectedAgent,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col className="Col-12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <ModalBody>
                      <div className="mb-3">
                        <AvField
                          name="name"
                          label="Name"
                          value={this.state.record.name}
                          className="form-control"
                          placeholder="Enter Team Name"
                          required
                        />
                      </div>
                       
                          <div className="mb-3">
                             <label className="control-label">Assign Agents</label>
                            <Select
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.user_id}
                                isMulti={true}
                                value={this.state.selectedAgent}
                                options={this.state.agents}
                                classNamePrefix="select2-selection"
                                name="agent_id"
                                onChange={(e) => {
                                    this.handleAgent(e)
                                }}
                            />
                            <AvField required type="hidden" name="agent_id" value={this.state.agent_id} />

                          </div>
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          id="s"
                          name="status"
                          label="Status"
                          required
                          value={this.state.record.status}
                        >
                          <option value={""}> Select Status </option>
                          <option value={"Active"}> Active </option>
                          <option value={"Inactive"}> Inactive </option>
                        </AvField>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </AvForm>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* /////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
