import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdownaction from "./Dropdown";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ProfileSettings from "../../Authentication/profile-sidebar";
export default class Billing extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      //   {
      //     key: "action",
      //     text: "Action",
      //     style: { overflow: "visible" },
      //     cell: (record) => {
      //       return <Dropdownaction userid={record.user_id} props={record.subscription_id}/>;
      //     }
      //     cell: (record, index) => {
      //       return

      //       (
      //         <Fragment>

      //              <Link
      //                   color="info"
      //                   className="btn btn-primary btn-sm mb-1 mr-5"
      //                   to={"/wl/subscription/history/" + bcrypt(record.user_id)}
      //                 >
      //                   <i className="mdi mdi-information"> Logs</i>
      //                 </Link>
      //                 <Link
      //                   color="info"
      //                   className="btn btn-primary btn-sm mb-1 mr-5"
      //                   to={
      //                     "/wl/subscription/history/invoice/" +
      //                     bcrypt(record.subscription_id)
      //                   }
      //                 >
      //                   <i className="mdi mdi-file-multiple">Invoice</i>
      //                 </Link>
      //         </Fragment>
      //       );
      //     },
      //   },
      {
        text: "transaction#",
        key: "transaction_id",
        sortable: false,
      },
      {
        text: "Package",
        key: "package_name",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.package_name} <sub> / Per {record.package_interval}</sub>
            </Fragment>
          );
        },
      },

      {
        text: "Payment method",
        key: "payment_method",
        sortable: false,
      },
      {
        text: "Price",
        key: "price",
        sortable: false,
      },
      {
        text: "Type",
        key: "type",
        sortable: false,
      },
      {
        text: "Dated",
        key: "created_at",
        sortable: false,
      },

      // {
      //   text: "Billing status",
      //   key: "billing_status",
      //   sortable: false,
      //   cell: (record, index) => {
      //     return (
      //       <Fragment>
      //         {record.billing_status == "Active" && (
      //           <Badge bg="success"> {record.billing_status} </Badge>
      //         )}
      //         {record.billing_status == "Inactive" && (
      //           <Badge bg="danger"> {record.billing_status} </Badge>
      //         )}
      //       </Fragment>
      //     );
      //   },
      // },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: false,
      show_pagination: true,
      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };
    this.state = {
      index: { btnInfo1: false },
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Billing",
      customDate: true,
    };
  }
  setBtnInfo1(index) {
    console.log(index, "debug");
    this.setState({
      index: { btnInfo1: true },
    });
  }

  componentDidMount() {
    this.getData();
  }
  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData(this.state.type, "");
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData('', values);
  };

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/user/transaction/list" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response, "debug");
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <Card className="mb-2">
                  <CardBody>
                    <AvForm
                      onValidSubmit={this.handleFilterSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-control"
                              type="select"
                              name="type"
                              label="Type"
                            >
                              <option value="">All</option>
                              <option value="Subscription">Revenue Subscription</option>
                              <option value="Other">Revenue (Non-Subscription)</option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="start_date"
                              label="Start date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="end_date"
                              label="End date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <label className=""> {"Action"}</label>
                            <div className="button-items">
                              <Button
                                className="btn-sm"
                                color="primary"
                                type="submit"
                              >
                                Filter
                              </Button>
                              <Button
                                onClick={() => this.cancelFilter()}
                                outline
                                color="secondary"
                                className="waves-effect btn-sm"
                                type="button"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {/* <div className="button-items">
                        <Button
                          className="btn-sm"
                          color="primary"
                          type="submit"
                        >
                          Filter
                        </Button>
                        <Button
                          onClick={() => this.cancelFilter()}
                          outline
                          color="secondary"
                          className="waves-effect btn-sm"
                          type="button"
                        >
                          Reset
                        </Button>
                      </div> */}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />

                    {/* <ReactDatatable
                      key={this.state.id}
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                    /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
