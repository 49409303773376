import React, { useState } from "react";
import { ApiUrl } from "../../config";
import {
  uToken,
} from "../../useToken";
import "./App.css";
import { toast, ToastContainer, Flip } from "react-toastify";
import Phone from "./Phone";
const OutCall = () => {
  const [token, setToken] = useState(null);
  const [clicked, setClicked] = useState(false);
  const identity = "proglobal";

  const handleClick = () => {
    setClicked(true);
    fetch(`${ApiUrl}` + "voice/token", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ identity: encodeURIComponent(identity) }),
        })
      .then(response => response.json())
      .then((response) => {
        console.log(response, "Test");

        if (response.status === true) {
          setToken(response.data.token);
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
const paramsters = {
        token: token,
        from_number: '+17752789670',
        phoneno: "12137252132",
        record: "false",
        client_id: 17,
        coach_id: 40,
        coach_partner_id: 41,
        campaign_id: 0
    };
  return (
    <div className="app">
      <header className="App-header">
        <h1>Twilio OutCall</h1>
      </header>

      <main>
        {!clicked && <button onClick={handleClick}>Connect to Phone</button>}

        {token ? <Phone paramsters={paramsters} token={token}></Phone> : <p>Loading...</p>}
      </main>
    </div>
  );
};

export default OutCall;
