import React from "react";
import { withRouter } from "react-router-dom";

//i18n
import SidebarContentAdmin from "./profile-settings-admin";
import SidebarContentManager from "./profile-settings-manager";
import SidebarContentManagerPartner from "./profile-settings-manager-partner";

import { uInfo } from "../../useToken";
// import SidebarContent from "./profile-settings-superadmin";
const Sidebar = (props) => {
  // console.log(props.props.role.id,'data here');

  return (
    <React.Fragment>
      <div className="vertical-menu" >
        <div data-simplebar className="h-100">
          
          {uInfo().type == 'WlAdmin' && (
            <SidebarContentAdmin />
          )}
          {uInfo().type == 'Coach' && (
            <SidebarContentManager />
          )}

          {uInfo().type == 'CoachPartner' && (
            <SidebarContentManagerPartner />
          )}
        </div>
      </div>
    </React.Fragment>
  );

};

export default Sidebar;
