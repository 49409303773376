import React from "react";
import { withRouter } from "react-router-dom";
import { hasRole, uInfo, uRole } from "../../useToken";

import AdminSidebar from "./AdminSidebar";
import CoachSidebar from "./CoachSidebar";
import ClientSidebar from "./ClientSidebar";

const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {uInfo().type === 'WlAdmin' && (
            props.type !== "condensed" ? <AdminSidebar /> : <AdminSidebar />
          )}
          {uInfo().type === 'Coach' && (
           props.type !== "condensed" ? <CoachSidebar /> : <CoachSidebar />
              
          
          )}
          {uInfo().type === 'CoachPartner' && (
            props.type !== "condensed" ? <ClientSidebar /> : <ClientSidebar />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;
