import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { toast, Flip } from "react-toastify";
import swal from "sweetalert";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl } from "../../config";
import Select from "react-select";

export default class Skills extends Component {
  constructor(props) {
    super(props);
    this.state = {
        record: {},
        languages: [],
        language: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${ApiUrl}get/user/skills`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
            this.setState({ 
                record: response.data.record, 
                languages: response.data.languages,
                selected_languages: response.data.record.selected_languages,
            });
        } 
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      fetch(`${ApiUrl}add/user/skills`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === true) {
            swal({
              text: "Skills updated successfully.",
              icon: "success",
            });
          } else {
            toast(data.message, {
              hideProgressBar: true,
              position: "top-center",
              transition: Flip,
              className: "toast-custom-cls error",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  handleLanguage = (selected_languages) => {
    var filter = [];
    if (selected_languages) {
      selected_languages.map((fil) => {
        filter.push(fil.id);
      });
    }

    this.setState({
      language: filter.toString(),
      selected_languages: selected_languages,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm onSubmit={this.handleSubmit} className="needs-validation">
                <Row>
                  <Col md={12}>
                    <ModalBody>
                      <div className="alert alert-info" role="alert">
                        Manage your skills. Clients will search for your account based on the skills defined below.
                      </div>

                      {/* Language Skill */}
                        <div className="col-md-12 mb-3">
                            <label className="control-label"> Language </label>
                            <div className="">
                              <Select
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isMulti={true}
                                value={this.state.selected_languages}
                                options={this.state.languages}
                                classNamePrefix="select2-selection"
                                name="language"
                                 placeholder="Enter your languages skill"
                                onChange={(e) => {
                                  this.handleLanguage(e);
                                }}
                              />
                        </div>
                            <AvField
                              
                              type="hidden"
                              name="language"
                              value={this.state.language}
                            />
                        </div>
                        <div className="col-md-12 mb-3">
                            <AvField
                              name="proficiency"
                              label="Language Proficiency Level"
                              type="select"
                              helpMessage="Select your language proficiency level"
                              validate={{
                                required: { value: true, errorMessage: "Please select your proficiency level." },
                              }}
                            >
                              <option value="">Select Proficiency</option>
                              <option value="beginner">Beginner</option>
                              <option value="intermediate">Intermediate</option>
                              <option value="advanced">Advanced</option>
                              <option value="native">Native</option>
                            </AvField>
                        </div>
                        <div className="col-md-12 mb-3">
                          <AvField
                            name="experience"
                            label="Years of Experience"
                            type="number"
                            placeholder="Enter your experience in years"
                            validate={{
                              required: { value: true, errorMessage: "Please enter your experience." },
                              min: { value: 0, errorMessage: "Experience cannot be negative." },
                            }}
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                  
                          <AvField
                            name="certifications"
                            label="Certifications"
                            type="text"
                            placeholder="Enter your certifications (comma-separated)"
                            validate={{
                              required: { value: true, errorMessage: "Please enter your certifications." },
                            }}
                          />

                        </div>
                   
                      <div className="col-md-12 mb-3">
                      <AvField
                        name="specializations"
                        label="Specializations"
                        type="text"
                        placeholder="Enter your specializations (comma-separated)"
                        validate={{
                          required: { value: false },
                        }}
                      />
                    </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Save Changes
                      </Button>
                    </ModalFooter>
                  </Col>
                </Row>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
