import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import {
  bcrypt,
  isAllowed,
  isObjectEmpty,
  logout,
  uInfo,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import Dropdownaction from "./Dropdown";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import swal from 'sweetalert';
import ChatUser from "./ChatUser";
export default class InterpreterClient extends Component {
  constructor(props) {
    super(props);

    this.columns = [
    {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          if (record.status !== 'Abandoned') {
            return (
              <>
                {record.is_approved === 'Yes' && (
                  <button
                    type="button"
                    color="info"
                    className="btn btn-primary btn-sm mb-1 mr-5"
                     onClick={() => { this.setState({ show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: record.id, userType: record.type }) }}
                        
                  >
                    Chat
                  </button>
                )}

                {this.state.action_reload && (
                  <Dropdownaction
                    userid={record.id}
                    props={record.id}
                    status={record.status}
                    record={record}
                    loginAsSubCoach={this.loginAsSubCoach}
                    trigger_get_user={this.trigger_get_user}
                    changeApprovalUser={this.changeApprovalUser}
                  />
                )}
              </>
            );
          }
            return null; // Return null if record.status is 'Abandoned'
          }
    },
    {
        text: "Name",
        key: "name",
        cell: (record, index) => {
          return (
            <Fragment>
              record.name
            </Fragment>
          );
        },
      },
      {
        text: "Email",
        key: "email",
      },
      {
        text: "Phone",
        key: "phone",
      },
      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          return (

            <Fragment>
              {
                record.status === "Active" ? (
                  <Badge bg="success"> {record.status} </Badge>
                ) :
                  (record.status === "Abandoned" || record.status === "Pending") ? (
                    <Badge bg="warning"> {record.status} </Badge>
                  ) :
                    <Badge bg="danger"> {record.status} </Badge>
              }
            </Fragment>
          );
        },
      },
      {
        text: "Approve Status",
        key: "is_approved",
        cell: (record, index) => {
          return (

            <Fragment>
              {
                record.is_approved !== "Yes" ? (
                  <button
                    disabled={record.status === 'Abandoned' ? true : false}
                    className="border-0 p-0 lh-1"
                    onClick={this.changeApprovalUser.bind(this, record, index)}
                  >
                    {record.is_approved === "No" && (
                      <Badge bg="warning"> {record.is_approved} </Badge>
                    )}

                    {record.is_approved === "Declined" && (

                      <Badge bg="danger"> {record.is_approved} </Badge>
                    )}
                  </button>
                ) : (
                  <Badge bg="success"> {record.is_approved} </Badge>
                )
              }
            </Fragment>
          );
        },

      },

      {
        text: "Created At",
        key: "created_at",
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      action_reload: true,
      records: [],
      coachPermission: {
        available_coach: 0,
        remaining_coach: 0,
      },
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Client List",
      customDate: true,
      approvalModal: false,
      approvalUser: {},
      approvalStatus: ''
    };
  }

  changeApprovalUser = (record, index) => {
    console.log(record, 'recordrecordrecord')
    this.setState({
      approvalModal: true,
      approvalUser: record,
      approvalStatus: ''
    })
  }

  changeApprovalStatus = (status) => {
    let record = this.state.approvalUser;
    let msg = 'You want to approve this user account';
    swal({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "approve/user/account/" + record.id + '/' + status, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                this.setState({
                  approvalUser: {},
                  approvalModal: false
                })
              } else {
                toast.error(data.message, { theme: "colored" });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          this.setState({
            approvalUser: {},
            approvalModal: false
          })
        }
      });
  };

  deleteRecord = (record, index) => {
    fetch(`${ApiUrl}` + "delete/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: record.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.getData();

          // toast.success(data.message, {
          //   theme: "colored",
          //   autoClose: ToastAutoClose,
          // });
        } else {
          toast.error(data.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  componentDidMount() {
    this.getData();
  }

  loginAsSubCoach = (user,users) => {
    logout();
    localStorage.setItem("authUser", JSON.stringify(user));
    localStorage.setItem("switchUser",  window.btoa(encodeURIComponent(users)));
    setTimeout(this.props.history.push("/"), 4000);
  }
  trigger_get_user = () => {
    //console.log('trigger_get_user');

    this.getData();
  }
  getData = (queryString = "", data) => {
    this.setState({ action_reload: false })
    let body = { type: 'CoachPartner' };
    if (data && !isObjectEmpty(data)) {
      body = data;
    }
    fetch(`${ApiUrl}` + "users" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
            coachPermission: response.data.coachPermission
          });
        } else {
          toast.error(response.message, { theme: "colored" });
        }
        this.setState({ action_reload: true });
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    values.type = 'Coach';
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };
  cancelSlider = async () => {
    this.setState({
      page2Class: 'slide-out2',
      editId: '',
      sliderShown: ''
    })
  };
  render() {
    return (
      <ErrorBoundary>
        <Helmet>
          <title>
            {this.state.page_name} </title>
        </Helmet>

        <Row>
          <Col sm={10}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">  {this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
           <Col sm={2}>
            <div className="page-title-box text-align-right">
              {(isAllowed(uInfo(), ["can_create"])) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-info"
                  to={"/users/client/add"}
                >
                  <i className="mdi mdi-plus"> </i>
                  Create
                </Link>
              )}
            </div>
          </Col> 
         
        </Row>
      { this.state.show4Slider && this.state.sliderShown == 'show4Slider' && (
          <div className={`page3 ${this.state.page2Class} custom-scroll`} >
            <div>
              <div className="modal-head top-heading">
                <h2>Live Chat</h2>
                <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <ChatUser cancelSlider={this.cancelSlider} userType={this.state.userType} id={this.state.editId} />
            </div>
          </div>
        )
      }
        <ToastContainer />
        <Modal
          isOpen={this.state.approvalModal}
          fade={false}
          toggle={() => this.setState({ approvalModal: false })}
        >
          <ModalBody>
            <div className="form-group mb-3">
              <label>Select Status</label>
              <select onChange={(e) => this.setState({ approvalStatus: e.target.value })} name="from_number" className="form-control" >
                <option value={''}>Select option</option>
                <option value={'No'}>No</option>
                <option value={'Yes'}>Yes</option>
                <option value={'Declined'}>Declined</option>
              </select>
            </div>
            {
              this.state.approvalStatus ==='Yes' && (
              <div className="form-group mb-3">
             
                
               {/* <label className="control-label">
                   <i className="ion ion-md-checkmark-circle-outline"> </i> I agree to create twilio subaccount for this interpreter <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="button-tooltip">
                            - Our system will create a Twilio sub-account under your Twilio master account for the interpreter after admin approval.<br/>
                            - The Twilio sub-account will allow the interpreter to purchase phone numbers for their clients.<br/>
                            - Our system will charge the interpreter $5 for each phone number every month.
                          </Tooltip>
                        }
                      >
                       <i className='fas fa-info'></i>
                    </OverlayTrigger>
                </label>*/}
            </div>
              )
            }
          </ModalBody>
          <ModalFooter>
            {
              this.state.approvalStatus !== '' ?
                <Button color="info" onClick={() => this.changeApprovalStatus(this.state.approvalStatus)}>
                  Yes, Change it
                </Button>
                :
                <Button disabled color="info" >
                  Yes, Change it
                </Button>
            }
            <Button color="danger" onClick={() => this.setState({ approvalModal: false })}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  key={this.state.id}
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ErrorBoundary>
    );
  }
}
