import React from "react"
import { Redirect } from "react-router-dom"
import UserProfile from "../pages/Authentication/user-profile"
import UserGlobalSetting from "../pages/Authentication/user-constraint-setting"
//import CustomApi from "../pages/Authentication/custom-api";
//import CustomApiPublic from "../pages/Authentication/custom-api-public";
import UserChangePassword from "../pages/Authentication/changePassword"
import UserBilling from "../pages/Authentication/billing"
import PhoneNumbers from "../pages/PhoneNumbers/index"
import PhoneNumberPurchase from "../pages/PhoneNumbers/purchase"
import MyNumbers from "../pages/PhoneNumbers/myNumbers"
//import AcceptedproposalView from "../pages/Authentication/AcceptedproposalView"


//import UserUpgrade from "../pages/Authentication/UserUpgrade";
//import CoachUpgrade from "../pages/Authentication/CoachUpgrade";
import Login from "../pages/Authentication/Login";

import ClientProfileComplete from "../pages/Authentication/ClientProfileComplete";


import ResetPassword from "../pages/Authentication/ResetPassword"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Dashboard from "../pages/Dashboard/index"
import TermOfServices from "../pages/TermOfServices/termofservices"
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
import SignupSelectPackage from "../pages/Authentication/SignupSelectPackage";
import SipupPackage from "../pages/Authentication/SipupPackage"
import SignupPayment from "../pages/Authentication/SignupPayment"
//import ProposalView from "../pages/Authentication/Proposal";


import dragDrop from "../pages/InitiateCall/index";
// import VideoMeeting from "../pages/Coaches/test-feature/meeting";


import PermissionList from "../pages/Permission/index";
import PermissionCreateUpdate from "../pages/Permission/PermissionCreateUpdate";
//import Logs from "../pages/Logs/Logs";
import UserDetails from "../pages/Users/UserDetails"


import ActivityLog from "../pages/Logs/ActivityLog";

//import TwilliousageLog from "../pages/Logs/TwilliousageLog";
//import CreditConsumptionLog from "../pages/Logs/CreditConsumptionLog";
//import CoachCreditConsumedHistory from "../pages/Logs/CreditConsumedHistory";


//import Admin from "../pages/Users/Admin/Admin";
//import AdminCreateUpdate from "../pages/Users/Admin/AdminCreateUpdate";

//import ApiCredentials from "../pages/Clients/ApiCredentials/ApiCredentials";


import TwillioSettings from "../pages/Settings/Integrations/TwillioSettings";

import EmailSettings from "../pages/Settings/EmailSettings/EmailSettings";
import Paymentsettings from "../pages/Settings/PaymentSettings/PaymentSettings";

import Creditsettings from "../pages/Settings/CreditSettings/Credits";


import CoachPartners from "../pages/Settings/CoachPartners/CoachPartners";
import CoachCreateUpdate from "../pages/Settings/CoachPartners/CoachCreateUpdate";

import Teams from "../pages/Teams/index";
import TeamCreateUpdate from "../pages/Teams/TeamCreateUpdate";



import CMSContent from "../pages/Settings/CMS/Content.js";
import CMSContentCreateUpdate from "../pages/Settings/CMS/CreateUpdate.js";

import TransactionLog from "../pages/Settings/Reports/TransactionLog";
import LoginHistory from "../pages/Settings/Reports/LoginHistory";
import SubscriptionLog from "../pages/Settings/Reports/SubscriptionLog";
import NonSubscriptionLog from "../pages/Settings/Reports/NonSubscriptionLog";



import CoachReports from "../pages/Coaches/Reports/index.js";


import SubscriptioHistory from "../pages/Settings/Reports/SubscriptioHistory";

import SubscriptionHistoryInvoice from "../pages/Settings/Reports/SubscriptionHistoryInvoice";


//import ManageContents from "../pages/Admin/ManageContents";
//import ManageProducts from "../pages/Admin/ManageProducts";
//import CreateContents from "../pages/Admin/CreateContents";
//import UpdateContents from "../pages/Admin/UpdateContents";

import Managers from "../pages/Users/Coaches/Managers";
import AdminManagers from "../pages/Users/Coaches/AdminManagers";
import ManagerCreateUpdate from "../pages/Users/Coaches/ManagerCreateUpdate";
import AdminManagerCreateUpdate from "../pages/Users/Coaches/AdminManagerCreateUpdate";

import InterpeterClient from "../pages/Users/Coaches/InterpreterClient";
import InterpeterClientCreateUpdate from "../pages/Users/Coaches/InterpeterClientCreateUpdate";

import Packages from "../pages/Packages/Packages";

import PackagesCreateUpdate from "../pages/Packages/PackagesCreateUpdate";
import PackagesAdd from "../pages/Packages/PackagesAdd";

import Email from "../pages/Settings/EmailTemplatesSettings/Email";
import EmailCreateUpdate from "../pages/Settings/EmailTemplatesSettings/EmailCreateUpdate";

import DefaultNotifications from "../pages/Settings/NotificationTemplatesSettings/Notification";
import NotificationCreateUpdate from "../pages/Settings/NotificationTemplatesSettings/NotificationCreateUpdate";

import Group from "../pages/Coaches/Group/Group";
import GroupCreateUpdate from "../pages/Coaches/Group/GroupCreateUpdate";

//import inbox from "../pages/Coaches/Inbox/index";
//import Leads from "../pages/Coaches/Lead/Leads";


import Contacts from "../pages/Coaches/Lead/index";
import ContactCreateUpdate from "../pages/Coaches/Lead/ContactCreateUpdate";

import Verification from "../pages/Verification"
import AccountNotification from "../pages/Verification/AccountNotification.js";
import RenewSubscription from "../pages/Verification/RenewSubscription.js";


import SystemWarning from "../pages/Notification/SystemWarning"

import VideoRoomHost from "../pages/RoomVideo/VideoChat"

import VideoRoomRemote from "../pages/RoomVideo/RemoteVideoChat"

const userRoutes = [

    //Admin routes 
 	{ path: "/dashboard", component: Dashboard, requiredRoles: [] },
    // Users
	{ path: "/settings/profile", component: UserProfile, requiredRoles: [] },
    { path: "/profile/password", component: UserChangePassword, requiredRoles: [] }, 
    
    { path: "/users/admin/interpreter/add", component: AdminManagerCreateUpdate, requiredRoles: ['coaches'] },
    { path: "/users/admin/interpreter/edit/:id", component: AdminManagerCreateUpdate, requiredRoles: ['coaches'] },
    { path: "/users/admin/interpreter", component: AdminManagers, requiredRoles: ['coaches'] },
 	{ path: "/user-profile/:id", component: UserDetails, requiredRoles: [] },
  
	{ path: "/users/client/add", component: InterpeterClientCreateUpdate, requiredRoles: [] },
    { path: "/users/client/edit/:id", component: InterpeterClientCreateUpdate, requiredRoles: [] },
    { path: "/users/client", component: InterpeterClient, requiredRoles: [] },

    { path: "/packages/interpreter/add", component: PackagesAdd, requiredRoles: ['coach_packages','credit_packages'] },
    { path: "/packages/interpreter/edit/:id", component: PackagesCreateUpdate, requiredRoles: ['coach_packages','credit_packages'] },
    { path: "/packages/interpreter/list", component: Packages, requiredRoles: ['coach_packages','credit_packages'] },


    //Staff Management
    // { path: "/users/staff/add", component: StaffCreateUpdate, requiredRoles: ['staff'] },
    // { path: "/users/staff/edit/:id", component: StaffCreateUpdate, requiredRoles: ['staff'] },
    // { path: "/users/staff", component: Staff, requiredRoles: ['staff'] },

    // permissionLibrary
    // { path: "/users/permissionLibrary/list", component: PermissionList, requiredRoles: ['permission_library'] },
    // { path: "/users/permissionLibrary/add", component: PermissionCreateUpdate, requiredRoles: ['permission_library'] },
    // { path: "/users/permissionLibrary/edit/:id", component: PermissionCreateUpdate, requiredRoles: ['permission_library'] },
    // Users

    //Packages
   

    // { path: "/packages/proposal/list", component: Proposals, requiredRoles: ['proposal_list'] },
    // { path: "/packages/proposal/add", component: ProposalsAdd, requiredRoles: ['proposal_list'] },
    // { path: "/packages/proposal/edit/:id", component: ProposalsAdd, requiredRoles: ['proposal_list'] },

    // { path: "/packages/proposal/content/:id", component: ProposalsContent, requiredRoles: ['proposal_list'] },
    // { path: "/packages/proposal/content/add/:proposal_id", component: ProposalsContentAdd, requiredRoles: ['proposal_list'] },
    // { path: "/packages/proposal/content/edit/:id/:proposal_id", component: ProposalsContentEdit, requiredRoles: ['proposal_list'] },
    // { path: "/print/proposal/pages/:id", component: PrintProposals, requiredRoles: ['proposal_list'] },

    //Revenue Goals Settings
    //{ path: "/packages/revenueGoals", component: RevenueGoalsSettings, requiredRoles: ['revenue_goals'] },

    //Revenue Products
    // { path: "/packages/revenueProducts", component: RevenueProducts, requiredRoles: ['revenue_products'] },
    // { path: "/settings/revenueProduct/add", component: RevenueProductAdd, requiredRoles: ['revenue_products'] },
    // { path: "/settings/revenueProduct/edit/:id", component: RevenueProductAdd, requiredRoles: ['revenue_products'] },
    //Packages

    //Credits
    // { path: "/credits/consume/criteria", component: CreditConsumptionCriteria, requiredRoles: ['consume_criteria'] },
    // { path: "/credits", component: FreeCredit, requiredRoles: ['free_credit'] },
    //Credits

    //Training
    //Category

    
    // { path: "/training/reorder", component: TrainingCategoryReorder, requiredRoles: ['training'] },
    // { path: "/training/category/add", component: TrainingCategoryCreateUpdate, requiredRoles: ['training'] },
    // { path: "/training/category/edit/:id", component: TrainingCategoryCreateUpdate, requiredRoles: ['training'] },
    // { path: "/training", component: TrainingCategory, requiredRoles: ['training'] },

    //Training Content
    // { path: "/training/content", component: TrainingContent, requiredRoles: ['training'] },
    // { path: "/training/content/add", component: TrainingContentAdd, requiredRoles: ['training'] },
    // { path: "/training/content/view/:id", component: TrainingContentView, requiredRoles: ['training'] },
    // { path: "/training/content/edit/:id", component: TrainingContentEdit, requiredRoles: ['training'] },

    // //Sections
    // { path: "/training/category/view/:id", component: TrainingCategorySections, requiredRoles: [] },
    // { path: "/training/quiz/question/:id", component: TrainingSectionsQuiz, requiredRoles: [] },
    // { path: "/training/categorysection/add/:cat_id", component: TrainingCategorySectionCreateUpdate, requiredRoles: [] },
    // { path: "/training/categorysection/edit/:id", component: TrainingCategorySectionCreateUpdate, requiredRoles: [] },


    //Resources
    // { path: "/resources", component: ResourcesCategory, requiredRoles: ['resources'] },
    // { path: "/resource/category/add", component: ResourceCategoryCreateUpdate, requiredRoles: ['resources'] },
    // { path: "/resource/category/edit/:id", component: ResourceCategoryCreateUpdate, requiredRoles: ['resources'] },


    //Resource Sections
    // { path: "/resource/category/view/:id", component: ResourcesCategorySections, requiredRoles: ['resources'] },
    // { path: "/resource/categorysection/add/:cat_id", component: ResourceCategorySectionCreateUpdate, requiredRoles: ['resources'] },
    // { path: "/resource/categorysection/edit/:id", component: ResourceCategorySectionCreateUpdate, requiredRoles: ['resources'] },

    //Resources Content
    // { path: "/resources/content", component: ResourcesContent, requiredRoles: ['resources'] },
    // { path: "/resource/content/add", component: ResourceContentAdd, requiredRoles: ['resources'] },
    // { path: "/resource/content/edit/:id", component:  ResourceContentEdit, requiredRoles: ['resources'] },
    // { path: "/resource/content/view/:id", component: ResourceContentView, requiredRoles: ['resources'] },

    // Manage Media
    //{ path: "/manage/media", component: ManageMediaContent, requiredRoles: [] },

    //Tracker
    // { path: "/tracker", component: Tracker, requiredRoles: ['tracker_steps'] },
    // { path: "/tracker/add", component: TrackerAdd, requiredRoles: ['tracker_steps'] },
    // { path: "/tracker/view/:id", component: TrackerView, requiredRoles: ['tracker_steps'] },
    // { path: "/tracker/edit/:id", component: TrackerEdit, requiredRoles: ['tracker_steps'] },

    //Assessment Question
    // { path: "/assessment/questions", component: AssessmentQuestion, requiredRoles: ['assessment_questions'] },
    // { path: "/assessment/questions/view/:id", component: AssessmentQuestionView, requiredRoles: ['assessment_questions'] },

    

    //Training

    //Activity 

    //  Log 
    //{ path: "/:type/activity/log", component: ActivityLog, requiredRoles: ['activity_log'] },
    //Twillio Usage Log
    //{ path: "/twillio/usagelog", component: TwilliousageLog, requiredRoles: ['twillio_usagelog'] },
    //Credit Consumption Log
    //{ path: "/credit/consumptionlog", component: CreditConsumptionLog, requiredRoles: ['credit_consumption_log'] },
    //  login/history
    { path: "/:path/login/history", component: LoginHistory, requiredRoles: ['coach_login_history'] },
    { path: "/:path/login/history/:date", component: LoginHistory, requiredRoles: [] },
    // Subscription Log
    { path: "/:path/subscription/log", component: SubscriptionLog, requiredRoles: ['coach_subscription_log'] },
    { path: "/:path/nonsubscription/log", component: NonSubscriptionLog, requiredRoles: ['coach_nonsubscription_log'] },
    //Activity 

    //CMS
    { path: "/cms/:path/add", component: CMSContentCreateUpdate, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    { path: "/cms/:path/view/:id", component: CMSContentCreateUpdate, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    { path: "/cms/:path", component: CMSContent, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    //Notification Templates
    { path: "/cms/notification/template/add", component: NotificationCreateUpdate, requiredRoles: ['default_template'] },
    { path: "/cms/notification/template/edit/:id", component: NotificationCreateUpdate, requiredRoles: ['default_template'] },
    { path: "/cms/notification/template", component: DefaultNotifications, requiredRoles: ['default_template'] },
    //CMS

    // Settings
    { path: "/settings/twilio", component: TwillioSettings, requiredRoles: ['settings_integration'] },
    { path: "/settings/stripe", component: Paymentsettings, requiredRoles: ['settings_payment','payment'] },
    { path: "/settings/sendgrid", component: EmailSettings, requiredRoles: ['settings_email'] },
    { path: "/caller/numbers/list", component: PhoneNumbers, requiredRoles: ['phone_numbers'] },
    { path: "/caller/numbers/purchase", component: PhoneNumberPurchase, requiredRoles: ['phone_numbers'] },
    
     { path: "/my/numbers", component: MyNumbers, requiredRoles: [] },

    { path: "/settings/billing", component: UserBilling, requiredRoles: ['billing'] },
    // { path: "/settings/domain", component: DomainSetting, requiredRoles: ['settings_domain'] },
    // { path: "/standard-profile/request", component: StandardProfileRequest, requiredRoles: ['standard_profile_request'] },
    // { path: "/standard-profile/request/:id", component: UserStandardProfileRequest, requiredRoles: ['standard_profile_request'] },
    // Settings
    //Admin routes 

    //Coach routes

    //Inbox
    //{ path: "/chat/inbox/:id/:coach_id", component: inbox, requiredRoles: ['inbox'] },
    
    // Leads
    // { path: "/import/phone/list", component: LeadsImport, requiredRoles: ['leads'] },
    // { path: "/contact/list", component: leads, requiredRoles: ['leads'] },
    // { path: "/contact/edit/:id/:coach_id", component: leadCreateUpdate, requiredRoles: ['leads'] },
    // { path: "/contact/add/", component: leadCreateUpdate, requiredRoles: ['leads'] },
    // { path: "/lead/dialer/:id/:coach_id", component: leadDetails, requiredRoles: ['leads'] },


    //lead Forms
    // { path: "/lead/forms", component: ClientForms, requiredRoles: ['leads'] },
    // { path: "/lead/forms/add", component: ClientFormsCreate, requiredRoles: ['leads'] },
    // { path: "/lead/forms/edit/:id", component: ClientFormsUpdate, requiredRoles: ['leads'] },


    // Client 
   { path: "/contact/list", component: Contacts, requiredRoles: [] },
    { path: "/contact/edit/:id/:coach_id", component: ContactCreateUpdate, requiredRoles: [] },
    { path: "/contact/add/", component: ContactCreateUpdate, requiredRoles: [] },
    // { path: "/client/dialer/:id/:coach_id", component: ClientDetails, requiredRoles: ['clients'] },

    //My coaches
    // { path: "/users/coach/add", component: ManagerCreateUpdate, requiredRoles: ['my_coaches'] },
    // { path: "/users/coach/edit/:id", component: ManagerCreateUpdate, requiredRoles: ['my_coaches'] },
    // { path: "/users/coach", component: Managers, requiredRoles: ['my_coaches'] },
    // { path: "/coach/dialer/:id/:coach_id/:wl_admin_id", component: CoachDetails, requiredRoles: ['my_coaches'] },

    // // Groups
    { path: "/phone/list/add", component: GroupCreateUpdate, requiredRoles: ['groups_and_tags'] },
    { path: "/phone/list/edit/:id", component: GroupCreateUpdate, requiredRoles: ['groups_and_tags'] },
    { path: "/phone/list", component: Group, requiredRoles: ['groups_and_tags'] },
    { path: "/call/report", component: CoachReports, requiredRoles: [] },
    // // CustomField
    // { path: "/custom/field/list", component: CustomField, requiredRoles: ['custom_fields'] },

    // //Sales
    // /// Pipeline
    // { path: "/pipeline/list", component: Pipeline, requiredRoles: ['pipeline'] },
    // { path: "/view/pipeline/:pipeline_id", component: PipelineView, requiredRoles: ['pipeline'] },
    // { path: "/pipeline/status/flow/:status_id", component: PipelineStatusFlow, requiredRoles: ['pipeline'] },

    // /// OnBoardTraining 
    // { path: "/training/status/list", component: OnBoardTraining, requiredRoles: ['onboard_training'] },

    // /// Contract
    // { path: "/contract/list", component: Contract, requiredRoles: ['contracts'] },
    // { path: "/contract/formbuilder/:id", component: ContractFormBuilder, requiredRoles: ['contracts'] },
    // { path: "/contract/form/mapping/:id", component: ContractFormMapping, requiredRoles: ['contracts'] },


    // //Invoice
    // { path: "/invoice/list", component: Invoice, requiredRoles: ['invoices'] },
    // { path: "/invoice/add/:id?/:type?", component: InvoiceCreate, requiredRoles: ['invoices'] },
    // { path: "/invoice/edit/:id", component: InvoiceUpdate, requiredRoles: ['invoices'] },


    // //Campaigns
    // { path: "/campaign/list", component: Campaign, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/add/", component: CampaignCreate, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/attachment/:id?", component: CampaignAttachment, requiredRoles: [] },
    // { path: "/campaign/edit/:id", component: CampaignUpdate, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/outbound/:id", component: CampaignOutbound, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/inbound/:id", component: CampaignInbound, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/ivr/update/:id/:ivr_id", component: CampaignIvrUpdate, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/batches/:id/:batch_id/:batch_type", component: CampaignBatches, requiredRoles: ['campaign_list'] },
    // { path: "/clients/batches/detail/:id/:batch_id", component: CampaignClientBatches, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/summary/:id", component: CampaignReportSummary, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/insights/:id", component: CampaignReportInsight, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/call/:id", component: CampaignReportCall, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/voice/:id", component: CampaignReportVoice, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/rvm/:id", component: CampaignReportRvm, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/sms/:id", component: CampaignReportSms, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/email/:id", component: CampaignReportEmail, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/manual/:id", component: CampaignReportManual, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/activity/:id", component: CampaignLog, requiredRoles: ['campaign_list'] },
    // //Campaign Automation
    // { path: "/campaign/automation/list/:id", component: CampaignAutomation, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/automation/flow/:id/:group_id/:flow_id", component: AutomationFlow, requiredRoles: ['campaign_list'] },
    // //Campaign Event Reminder
    // { path: "/campaign/eventreminder/:id/:group_id/:flow_id", component: EventReminderFlow, requiredRoles: ['campaign_list'] },
    // //Campaign drips
    // { path: "/campaign/drips/:id", component: CampaignDrip, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/drips/flow/:id/:group_id/:flow_id", component: CampaignDripFlow, requiredRoles: ['campaign_list'] },
    // //Campaign Broadcasts
    // { path: "/campaign/broadcasts/:id", component: CampaignBroadcasts, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/broadcasts/flow/:id/:groupId", component: CampaignBroadcastsFlow, requiredRoles: ['campaign_list'] },




    // // CallerStatus
    // { path: "/callstatus/list", component: CallerStatus, requiredRoles: ['call_status'] },

    // //Caller Group
    // { path: "/callergroup/list", component: CallerGroup, requiredRoles: ['caller_group'] },
    // { path: "/callergroup/add", component: CallerGroupCreate, requiredRoles: ['caller_group'] },
    // { path: "/callergroup/edit/:id", component: CallerGroupUpdate, requiredRoles: ['caller_group'] },

    // //Templates
    // //Email
    // { path: "/emailtemplates/list", component: EmailTemplates, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/add/", component: EmailTemplateslist, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/edit/:id", component: EmailTemplatesedit, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/create/:id", component: EmailTemplatescreate, requiredRoles: ['template_library'] },


    // //SMS Template
    // { path: "/smstemplates/list", component: SMSTemplates, requiredRoles: ['template_library'] },
    // { path: "/smstemplates/add/", component: SMSTemplatesAddEdit, requiredRoles: ['template_library'] },
    // { path: "/smstemplate/edit/:id", component: SMSTemplatesEdit, requiredRoles: ['template_library'] },

    // //Script Template
    // { path: "/agent/script/list", component: ScriptTemplates, requiredRoles: ['template_library'] },
    // { path: "/agent/script/add/", component: ScriptTemplatesAddEdit, requiredRoles: ['template_library'] },
    // { path: "/agent/script/edit/:id", component: ScriptTemplatesEdit, requiredRoles: ['template_library'] },

    // //VoiceMail Template
    // { path: "/voicetemplates/list", component: VoiceTemplates, requiredRoles: ['template_library'] },
    // { path: "/voicemailtemplate/add/", component: VoiceMailTemplatesAddEdit, requiredRoles: ['template_library'] },
    // { path: "/voicemailtemplate/edit/:id", component: VoiceMailTemplatesEdit, requiredRoles: ['template_library'] },

    // // UnsubKeywords
    // { path: "/unsubkeywords/list", component: UnsubKeywords, requiredRoles: ['unsubscribe_keywords'] },

    // //Coaching
    // //Assessment

    // { path: "/assessment/list/:type?", component: AssesmentsList, requiredRoles: ['assessment_list'] },
    // { path: "/assessment/:id/:slug", component: Assesments, requiredRoles: [] },
    // { path: "/assessment/:id/:slug/:result", component: Assesments, requiredRoles: [] },
    // { path: "/assessment/:id/:slug/:cal/:key", component: ImplementationContent, requiredRoles: [] },
    // { path: "/profit/center/:id/:slug", component: ProfitCenter, requiredRoles: [] },
    // { path: "/progress/tracker/:id/:slug", component: ProgressTracker, requiredRoles: [] },

    // //Training
    // { path: `/coach/trainings`, component: CoachTraining, requiredRoles: ['trainings'] },
    // { path: `/coach/trainings/view/:id*`, component: CoachTrainingView, requiredRoles: ['trainings'] },
    // { path: `/training/section/:id`, component: TrainingSection, requiredRoles: ['trainings'] },

    // //Resources Section 
    // { path: "/assesments/resources", component: AssessmentsResourcesProducts, requiredRoles: ['resources'] },
    // { path: "/resource/product/contentview/:id/:cont_id", component: AssesmentResourcesProductContentVideo, requiredRoles: ['resources'] },


    // //Report
    // //Reporting
    // { path: "/report/summary", component: AioReportSummary, requiredRoles: ['reporting'] },
    // { path: "/report/insights", component: AioReportInsight, requiredRoles: ['reporting'] },
    // { path: "/call/log", component: AioReportCall, requiredRoles: [] },

 	// { path: "/recycle/option/:id?", component: RecycleOptions, requiredRoles: [] },
 	

    // { path: "/report/voice", component: AioReportVoice, requiredRoles: ['reporting'] },
    // { path: "/report/rvm", component: AioReportRvm, requiredRoles: ['reporting'] },
    // { path: "/report/sms", component: AioReportSms, requiredRoles: ['reporting'] },
    // { path: "/report/email", component: AioReportEmail, requiredRoles: ['reporting'] },
    // { path: "/report/manual", component: AioReportManual, requiredRoles: ['reporting'] },
    // { path: "/report/activity", component: AioLog, requiredRoles: ['reporting'] },

    // //Credit Log 
    // { path: "/report/credithistory", component: CoachCreditConsumedHistory, requiredRoles: ['credit_log'] },

    // //Settings

    // //Coach Partner Settings
    // { path: "/agents/list", component: CoachPartners, requiredRoles: [] },
    // { path: "/agents/edit/:id", component: CoachCreateUpdate, requiredRoles: [] },
    // { path: "/agents/add/", component: CoachCreateUpdate, requiredRoles: [] },

    //  //Coach Partner Settings
    // { path: "/teams/list", component: Teams, requiredRoles: [] },
    // { path: "/teams/edit/:id", component: TeamCreateUpdate, requiredRoles: [] },
    // { path: "/teams/add/", component: TeamCreateUpdate, requiredRoles: [] },

    // //Credits
    // { path: "/settings/credits", component: Creditsettings, requiredRoles: ['credits'] },

    // //Calendar
    // { path: "/settings/calendar", component: Calendar, requiredRoles: ['calendar'] },
    // { path: "/settings/google/calendar/:id", component: googleCalendar, requiredRoles: [] },

    // //PhoneNumber
    // 

    // //Billing
    // ,

    // //TransactionLog
    // { path: "/settings/transactionlog", component: TransactionLog, requiredRoles: ['transaction_log'] },

    // //Api
    // { path: "/settings/custom/api", component: CustomApi, requiredRoles: ['api'] },

    // //Builder
    // { path: "/build/website", component: BuildWebsite, requiredRoles: ['website_builder'] },
    // { path: "/branding", component: Branding, requiredRoles: ['website_builder'] },
    // { path: "/manage-contents", component: ManageContents, requiredRoles: ['website_builder'] },
    // { path: "/manage-products", component: ManageProducts, requiredRoles: ['website_builder'] },
    // { path: "/create/content", component: CreateContents, requiredRoles: ['website_builder'] },

    // //Coach routes

    // { path: "/trainings/:id*", component: Waybook, requiredRoles: [] },
    // //Commom Routes
   

    // { path: "/chat/:id?/:type?", component: Chat, requiredRoles: [] },
    // { path: "/settings/global", component: UserGlobalSetting, requiredRoles: ['global_constraint_setting'] },


    // { path: "/acceptedproposal/view/:id", component: AcceptedproposalView, requiredRoles: [] },
    // { path: "/settings/upgrade", component: UserUpgrade, requiredRoles: [] },
    { path: "/test/feature", component: dragDrop, requiredRoles: [] },
    // // { path: "/test/meeting", component: VideoMeeting, requiredRoles: [] },
    // // Coach Upgarde
    // { path: "/settings/coachpartner/packages", component: CoachUpgrade, requiredRoles: [] },

    // //Resellers management
    // { path: "/users/admin/add", component: AdminCreateUpdate, requiredRoles: [] },
    // { path: "/users/admin/edit/:id", component: AdminCreateUpdate, requiredRoles: [] },
    // { path: "/users/admin/", component: Admin, requiredRoles: [] },
    // { path: "/logs", component: Logs, requiredRoles: ['Logs'] },
    //
    // { path: "/pages-blank", component: PagesBlank, requiredRoles: [] },
    // // coaches Reports
    // { path: "/aio/reporting", component: CoachReports, requiredRoles: [] },
    // { path: "/domain/setting", component: WLDomain, requiredRoles: [] },
    // { path: "/theme/", component: Theme, requiredRoles: [] },
    // { path: "/settings/themes", component: WLTheme, requiredRoles: [] },
    // // Api Credentials
    // { path: "/apicredentials", component: ApiCredentials, requiredRoles: [] },
    // //{ path: "/apicredentials/edit/:id", component: ApiCredentialsCreateUpdate, requiredRoles: ['Api Credentials'] },
    // // RVM(Slybroadcast) Settings
    // { path: "/settings/integration/rvm", component: RvmSettings, requiredRoles: [] },
    // // Email Packages
    // { path: "/email/add", component: EmailCreateUpdate, requiredRoles: ['Email'] },
    // { path: "/email/edit/:id", component: EmailCreateUpdate, requiredRoles: ['Email'] },
    // { path: "/emails", component: Email, requiredRoles: ['Email'] },

    // { path: "/credit/logs/:id", component: CreditsLog, requiredRoles: [] },


    // { path: "/contact/support", component: ContactSupport, requiredRoles: [] },

    // // ClientTraining
    // { path: `/client/trainings`, component: ClientTraining, requiredRoles: [] },
    // { path: `/client/trainings/view/:id*`, component: ClientTrainingView, requiredRoles: [] },
    // { path: `/client/training/section/:id`, component: ClientTrainingSection, requiredRoles: [] },

    // //Coach Training Section
    // { path: "/assessments/trainings", component: AssesmentTrainingProducts, requiredRoles: [] },
    // { path: "/training/product/content/:id/:index?", component: AssesmentTrainingProductContent, requiredRoles: [] },
    // // { path: "/training/product/content/:id/:cont_id", component: AssesmentTrainingProductContent, requiredRoles: [] },
    // { path: "/training/product/contentvideo/:id/:cont_id/:index?", component: AssesmentTrainingProductContentVideo, requiredRoles: [] },

    // { path: "/take/quiz/:id", component: AssesmentTrainingTakeQuiz, requiredRoles: [] },
    // //Faq's
    // { path: "/training/category/faqs/:id", component: TrainingCategoryFaqs, requiredRoles: [] },
    // { path: "/training/categorysection/faq/edit/:id", component: TrainingCategorySectionfaqCreateUpdate, requiredRoles: [] },
    // { path: "/training/categorysection/faq/add/:cat_id", component: TrainingCategorySectionfaqCreateUpdate, requiredRoles: [] },

    // //Tracking Code
    // { path: "/settings/trackingcode", component: Trackingcode, requiredRoles: [] },
    // //Email Packages

    // { path: "/:path/subscription/history/:sub_id/:user_id", component: SubscriptioHistory, requiredRoles: [] },
    // { path: "/:path/subscription/history/invoice/:sub_id/:user_id", component: SubscriptionHistoryInvoice, requiredRoles: [] },

    //Credit Log
    //TableSetting
    // { path: "/tablessetting", component: TableSetting, requiredRoles: [] },
    // { path: "/settings/clientstable", component: TableLeadSetting, requiredRoles: [] },

    //TableSetting

    // Leads 
    // { path: "/leads", component: Leads, requiredRoles: [] },
    // { path: "/leads/edit/:id/:manager_id", component: LeadsCreateUpdate, requiredRoles: [] },
    // { path: "/leads/add/", component: LeadsCreateUpdate, requiredRoles: [] },

    // { path: "/lead-details/:id/:manager_id", component: LeadDetails, requiredRoles: [] },
    //LEads


    // Leads 
    // { path: "/company/list", component: companies, requiredRoles: [] },
    // { path: "/company/edit/:id/:coach_id", component: CompanyCreateUpdate, requiredRoles: [] },
    // { path: "/company/add/", component: CompanyCreateUpdate, requiredRoles: [] },
    // { path: "/company/dialer/:id/:coach_id", component: companyDetails, requiredRoles: [] },


    // // Tasks 
    // { path: "/tasks/list", component: Tasks, requiredRoles: [] },
    // // Call Testing 
    // { path: "/test/sample", component: CallTesting, requiredRoles: [] },
    // { path: "/filter/list", component: Filters, requiredRoles: [] },
    // { path: "/filter/add", component: FilterAdd, requiredRoles: [] },
    // { path: "/filter/edit/:id", component: FilterUpdate, requiredRoles: [] },
    // { path: "/meeting/list", component: Meeting, requiredRoles: [] },
    // { path: "/meeting/add/", component: MeetingCreate, requiredRoles: [] },
    // { path: "/meeting/edit/:id", component: MeetingUpdate, requiredRoles: [] },
    // { path: "/appointment/list/:id", component: appointmentList, requiredRoles: [] },
    // { path: "/invoice/preview/:id", component: InvoiceDetail, requiredRoles: [] },
    // { path: "/invoice/pay/:id", component: InvoicePay, requiredRoles: [] },
    //Test
    // { path: "/emailtemplatetesting", component: EmailTemplateslist, requiredRoles: [] },
    // { path: "/system/warnings", component: SystemWarning, requiredRoles: [] },
    // { path: "/form/details", component: ClientFormDetails, requiredRoles: [] },
    // { path: "/show/form", component: ClientFormShow, requiredRoles: [] },
    // { path: "/contracts", component: ClientContracts, requiredRoles: [] },
    // { path: "/client/profile", component: ClientProfile, requiredRoles: [] },
    // { path: "/view/meeting", component: MeetingDetails, requiredRoles: [] },
    // { path: "/video/room/host", component: VideoRoomHost, requiredRoles: [] },
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => < Redirect to="/dashboard" />, requiredRoles: [] },
    { component: Pages404, requiredRoles: [] },
]
const authRoutes = [
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
    { path: "/reset/password/:token/:email", component: ResetPassword },
]

const noHeaderRoutes = [
    { path: "/change/initial/password", component: Verification, requiredRoles: [] },
    // {  component: Pages404 },
]


export { userRoutes, authRoutes, noHeaderRoutes }