import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import SweetAlert from "react-bootstrap-sweetalert"
import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select"
import countries from "../../common/data/countries";
import moment from 'moment';
import { Tabs, Tab } from "react-bootstrap";
import swal from 'sweetalert';

export default class Avilability extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduling: {
                title: 'Avilability',
                WorkingDays: [
                    {
                        key: '1',
                        day: 'Mon',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '2',
                        day: 'Tue',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '3',
                        day: 'Wed',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '4',
                        day: 'Thu',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '5',
                        day: 'Fri',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '6',
                        day: 'Sat',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'off'
                    },
                    {
                        key: '7',
                        day: 'Sun',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'off'
                    },

                ]
            },

        };
    }

    componentDidMount() {
       this.getData();
    }


    getData = () => {
        fetch(`${ApiUrl}` + "get/user/availability", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          if (response.data.record) {
            this.setState({
              scheduling: response.data.record
            })
          }

          this.setState({
            loading: false
          })

        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    };


    handleSubmit = async (event, errors, values) => {
        values.schedule_settings = JSON.stringify(this.state.scheduling);
       
        fetch(`${ApiUrl}` + "add/user/availability", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data, 'Editing');
            if (data.status === true) {
                swal({
                text: "Updated successfully.",
                icon: "success",
              });

            } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
        })
        //Then with the error genereted...
        .catch((error) => {
            console.error("Error:", error);
        });
    };


    StartHours = () => {
        const items = [];
        new Array(24).fill().forEach((acc, index) => {
            items.push(moment({ hour: index }).format('h:mm A'));
            items.push(moment({ hour: index, minute: 30 }).format('h:mm A'));
        });
        return items;
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <AvForm
                                onSubmit={this.handleSubmit}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <Row>
                                    <Col md={12}>
                                        <ModalBody >
                                         <div className="alert alert-info" role="alert">
                                          Manage your availability. Client calls will be forwarded based on the availability times defined below.
                                        </div>
                                        {
                                            this.state.scheduling.WorkingDays.map((d, j) => {
                                              return (
                                                <div key={j} className="row mb-3">
                                                  <div className="col-md-3">
                                                    <AvGroup>
                                                      <AvInput
                                                        placeholder="Enter business day"
                                                        className="form-control"
                                                        name={'day_' + d.key}
                                                        value={d.day}
                                                        readOnly
                                                        required />
                                                      <AvFeedback> Required Field *</AvFeedback>
                                                    </AvGroup>
                                                  </div>
                                                  <div className="col-md-3">
                                                    <AvField type="select"
                                                      name={'available_' + d.key}
                                                      label=""
                                                      value={d.available}
                                                      onChange={(e) =>
                                                        this.setState(prevState => {
                                                          let WorkingDays = Object.assign({}, prevState.scheduling.WorkingDays);
                                                          WorkingDays[j].available = e.target.value;
                                                          return { WorkingDays };
                                                        })
                                                      }
                                                    >
                                                      <option value="on">On</option>
                                                      <option value="off">Off</option>


                                                    </AvField>
                                                  </div>
                                                  <div className="col-md-3">
                                                    <AvField type="select"
                                                      name={'start_time_' + d.key}
                                                      label=""
                                                      value={d.start}
                                                      onChange={(e) =>
                                                        this.setState(prevState => {
                                                          let WorkingDays = Object.assign({}, prevState.scheduling.WorkingDays);
                                                          WorkingDays[j].start = e.target.value;
                                                          return { WorkingDays };
                                                        })
                                                      }
                                                      disabled={d.available == 'off' ? true : false}
                                                    >
                                                      {
                                                        this.StartHours().map(hour => {
                                                          return (
                                                            <option key={hour} value={hour}>{hour}</option>
                                                          )
                                                        })
                                                      }


                                                    </AvField>
                                                  </div>
                                                  <div className="col-md-3">
                                                    <AvField type="select"
                                                      name={'end_time_' + d.key}
                                                      label=""
                                                      value={d.end}
                                                      onChange={(e) =>
                                                        this.setState(prevState => {
                                                          let WorkingDays = Object.assign({}, prevState.scheduling.WorkingDays);
                                                          WorkingDays[j].end = e.target.value;
                                                          return { WorkingDays };
                                                        })
                                                      }
                                                      disabled={d.available == 'off' ? true : false}
                                                    >
                                                      {
                                                        this.StartHours().map(hour => {
                                                          return (
                                                            <option key={hour} value={hour}>{hour}</option>
                                                          )
                                                        })
                                                      }

                                                    </AvField>
                                                  </div>
                                                </div>
                                              );
                                            })
                                          }
                                        </ModalBody>
                                        <ModalFooter>
                                            <button
                                                className="btn btn-primary w-md waves-effect waves-light"
                                                type="submit"
                                            >
                                                Save Changes
                                            </button>
                                        </ModalFooter>
                                    </Col>

                                </Row>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
