import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import ReactApexChart from "react-apexcharts";

class YearlySales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todays_login: "",
      yesterdays_login: "",
      current_week_login: "",
      lastweek_login: "",
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "dashboard/loginlogs", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
         
          this.setState({
            current_week_login: response.data.currentweek,
            lastweek_login: response.data.lastweek_login,
            todays_login: response.data.todays_login,
            yesterdays_login: response.data.yesterdays_login,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Col xl="3">
          <Link to={"coach/login/history/today"}>
            <div className="mini-stat bg-primary card">
              <div className="card-body mini-stat-img card-body">
                <div className="mini-stat-icon">
                  <i className="float-end mdi mdi-login"></i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-1 font-size-16 text-white">
                    Today Logins
                  </h6>
                  <h2 className="mb-4 text-white">{this.state.todays_login}</h2>
                </div>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl="3">
          <Link to={"coach/login/history/yesterday"}>
            <div className="mini-stat bg-primary card">
              <div className="card-body mini-stat-img card-body">
                <div className="mini-stat-icon">
                  <i className="float-end mdi mdi-login"></i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-1 font-size-16 text-white">
                    Yesterday Logins
                  </h6>
                  <h2 className="mb-4 text-white">{this.state.yesterdays_login}</h2>
                </div>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl="3">
          <Link to={"coach/login/history/currentweek"}>
            <div className="mini-stat bg-primary card">
              <div className="card-body mini-stat-img card-body">
                <div className="mini-stat-icon">
                  <i className="float-end mdi mdi-login"></i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-1 font-size-16 text-white">
                    Current Week
                  </h6>
                  <h2 className="mb-4 text-white">
                    {this.state.current_week_login}
                  </h2>
                </div>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl="3">
          <Link to={"coach/login/history/lastweek"}>
            <div className="mini-stat bg-primary card">
              <div className="card-body mini-stat-img card-body">
                <div className="mini-stat-icon">
                  <i className="float-end mdi mdi-login"></i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-1 font-size-16 text-white">
                    Last Week Logins
                  </h6>
                  <h2 className="mb-4 text-white">{this.state.lastweek_login}</h2>
                </div>
              </div>
            </div>
          </Link>
        </Col>
      </React.Fragment>
    );
  }
}

export default YearlySales;
