import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Chat from "../../Chat/index";

export default class ChatUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id != '' ? this.props.id : '',
        };
    }

    render() {
        return (
            <React.Fragment>
              <Row className="m-1">
                <Col className="col-12">
                  {this.state.id && <Chat id={this.state.id} type={'Coach'} />}
                </Col>
              </Row>
            </React.Fragment>

        );
    }
}
