import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ProfileSettings from "../../Authentication/profile-sidebar";
import "react-toastify/dist/ReactToastify.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  uToken,
 uInfo, 
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose, AttachementsUrl } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import UploadJsonFile from "../../../components/Common/UploadJsonFile";
import Loader from "react-loader";
import swal from 'sweetalert';
export default class TwillioSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      tab: "1",
      id: uInfo().user_id,
      record: {},
      recordrvm: {},
      record_fb: {},
      page_name: this.props.match.params.id ? "Twilio Credentials" : "Twilio Credentials",
      files: "",
      loader: true,
      googleData: [],

    };
  }
  toggle(index) {
    this.setState({
      tab: index,
    });
  }
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/twilliosettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  handleSubmittwilio = async (event, values) => {
    console.log('dddddddddddd')
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/twilio", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
        swal({
            text: "Twilio Credentials saved successfully.",
            icon: "success",
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleChange = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0]);
    fileReader.onload = e => {
      console.log("e.target.result", e.target.result);
    };
    this.setState({
      files: e.target.files[0]
    })
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              <TabContent activeTab={this.state.tab}>
                <TabPane tabId="1">
                  <Row>
                  <Col className="col-12">
                    <div className="alert alert-info">
                      <p>
                        <i className="fas fa-question"> </i> Help
                      </p>
                      <p>
                        If you have not set up your Twilio account, you can do so by visiting
                        <span>
                          <a
                            className=""
                            href="https://www.twilio.com/try-twilio"
                            target="_blank"
                          >
                            &nbsp;Twilio Sign Up
                          </a>
                        </span>.
                      </p>

                      <p>
                        After creating your account, you can find your Account SID and Auth Token
                        by navigating to your
                        <span>
                          &nbsp;
                          <a
                            className=""
                            href="https://www.twilio.com/console"
                            target="_blank"
                          >
                            Twilio Console
                          </a>
                        </span>.
                      </p>

                      <p>
                        To access your credentials, go to the
                        <span>
                          &nbsp;
                          <a
                            className=""
                            href="https://www.twilio.com/console/project/settings"
                            target="_blank"
                          >
                            Project Settings
                          </a>
                        </span>
                        &nbsp;section in your Twilio Console.
                      </p>

                      <p>
                        You will find your Account SID and Auth Token under the
                        <strong> API Credentials </strong> section.
                        Keep them safe and secure, as they are sensitive information.
                      </p>

                      <p>
                        If you need to reset your Auth Token, you can do so by going to the
                        <span>
                          &nbsp;
                          <a
                            className=""
                            href="https://www.twilio.com/console/project/settings/auth-token"
                            target="_blank"
                          >
                            Auth Token Settings
                          </a>
                        </span>.
                      </p>
                    </div>
                  </Col>

                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmittwilio}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody>
                            <div className="mb-3">
                              <AvField
                                name="twilio_sid"
                                type="password"
                                 label={<span>Twilio SID<span className="required">*</span></span>}
                                placeholder="*********"
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="twilio_auth_token"
                                type="password"
                                label={<span>Twilio Auth Token<span className="required">*</span></span>}
                                value={this.state.record.twilio_auth_token}
                                className="form-control"
                                placeholder="Twilio Auth Token"
                                required
                              />
                            </div>
                          </ModalBody>
                          <ModalFooter>
                         
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Save Changes
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
