import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,

  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import PackagesSidebar from "./packages-sidebar";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { dcrypt, bcrypt, isAllowed, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";

export default class Packages extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "#",
        key: "package_id",
        sortable: true,
      },
      {
        text: "Title",
        key: "name",
      },
      {
        text: "Interval",
        key: "package_interval",
      },
      {
        text: "Price",
        key: "price",
      },
     
      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.status == "Active" && (
                <Badge bg="success">{record.status}</Badge>
              )}
              {record.status == "Inactive" && (
                <Badge bg="danger">{record.status}</Badge>
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Type",
        key: "package_type",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.package_type == "default" && (
                <Badge bg="success">Default</Badge>
              )}
              {record.package_type == "custom" && (
                <Badge bg="info">Custom</Badge>
              )}
            </Fragment>
          );
        },
      },
      {
        key: "link",
        text: "Share Link",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.package_type == 'default' ? (
                '--'
              ) : (
                <button
                  title="Link"
                  type="button"
                  color="info"
                  className="btn btn-link btn-sm mb-1 mr-5"
                  onClick={() => { this.setState({ modal_link: true, package_link: record.package_link }) }}
                >
                  <i className="fas fa-fw  fa-link"></i>
                </button>
              )
              }


            </Fragment>
          );
        },
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_edit"]) && (
                <Link
                  className="btn btn-primary btn-sm mb-1  mr-5"
                  to={"edit/" + bcrypt(record.package_id)}
                // onClick={() => {
                //   this.setState({
                //     modal: !this.state.modal
                //   })
                // }}
                >
                 Edit
                </Link>
              )}
              {isAllowed(uInfo(), ["can_delete"]) && record.package_type == 'custom' && (
                <>
                
                  <Link
                    className="btn btn-danger mb-1  btn-sm"
                    onClick={this.deleteRecord.bind(this, record, index)}
                  >
                   Delete
                  </Link>
                </>
              )}
            </Fragment>
          );
        },
      },
    ];

    
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
    };
    this.config1 = {
      page_size: 0,
      length_menu: [],
      show_filter: false,
      show_pagination: false,
    };
    this.state = {
      type: 'coach',
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      modal_link: false,
      package_link: "",
      record: {},
      filter: {},
      row: {},
      page_name: "Package ",
      customDate: true,
      coach_license_addon: [],
      premium_records: []
    };
  }

  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/package", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.package_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      });
  };

  componentDidMount() {
    this.getData();

  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.type !== prevProps.match.params.type) {
      this.setState({
        type: 'coach',
      });
      this.getData();
    }

  }

  getData = (queryString = "coach?all=1", data) => {
    fetch(`${ApiUrl}` + "packages/list/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            coach_license_addon: response.data.records[3],
          });

        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData(this.props.match.params.type, values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData(this.props.match.params.type, "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData(this.props.match.params.type + "?" + queryString + "&all=1", this.state.filter);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/additional/licence", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  getPremiunLicense = (key) => {
    if (key == 'premium_license') {
      fetch(`${ApiUrl}` + "premium/license", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          if (response.status === true) {
            console.log(response.data.records)
            this.setState({
              premium_records: response.data.records,
            });
          } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })

        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  deletePremiunLicense = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/premium/license", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getPremiunLicense('premium_license');
                //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      });
  };

  submitLicense = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "add/premium/license", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            row: {},
            modal: false
          })
          this.getPremiunLicense('premium_license');
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        this.setState({ loader: true });
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          
          <Col lg="12">
            <Row>
              <Col sm={10}>
                <div className="page-title-box">
                  <h4>
                    Packages List
                  </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">
                        Packages List
                      </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={2}>
                <div className="page-title-box text-align-right">
                  {(isAllowed(uInfo(), ["can_create"])) && (
                    <Link
                      type="button"
                      color="info"
                      className="btn btn-info"
                      to={"add"}
                    >
                      <i className="mdi mdi-plus"> </i>
                      Create
                    </Link>
                  )}
                </div>
              </Col>

                          
            </Row>

            <ToastContainer />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                      <>
                            <ReactDatatable
                              config={this.config1}
                              //key={this.state.id}
                              records={this.state.records}
                              columns={this.columns}
                              dynamic={true}
                              total_record={this.state.total_pages}
                              onChange={this.tableChangeHandler}
                            />
                      </>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.modal_link}
              fade={false}
              toggle={() => this.setState({ modal_link: false })}
            >
              <ModalHeader>Package Signup Link</ModalHeader>
              <ModalBody>
                <div className="url">
                  <a
                    href={`${this.state.package_link}`}
                    target="_blank"
                    className="pr-5 mr-5"
                  >
                    {this.state.package_link}
                  </a>

                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="overlay-example">
                        Copy url
                      </Tooltip>
                    }
                  >
                    <button
                      className="btn btn-link btn-md"
                      onClick={() => {
                        navigator.clipboard.writeText(`${this.state.package_link}`);
                      }}
                    >
                      <i className="fas fa-copy"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="info"
                  onClick={() => this.setState({
                    modal_link: false,
                    package_link: ""
                  })}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}
