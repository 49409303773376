import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import {
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
  } from "../../useToken";
class MonthlyEarnings extends Component {
    constructor(props) {
        super(props);

        this.state = {
           activeCoaches:'',
           inactiveCoaches:'',
           suspendedCoaches:'',
           canceledCoaches:'',
        }
        
    }
    componentDidMount() {
        this.getData();
      }

      getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "dashboard/userstatistics", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((response) => {
            if (response.status === true) {
          
              this.setState({
                activeCoaches:response.data.activeCoaches,
                inactiveCoaches:response.data.inactiveCoaches,
                suspendedCoaches:response.data.suspendedCoaches,
                canceledCoaches:response.data.canceledCoaches,
              });
            } else {
                        
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
          })
          //Then with the error genereted...
          .catch((error) => {
            console.error("Error:", error);
          });
      };
    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">User  Statistics</h4>

                        <Row className="text-center mt-4">
                            <Col xs="3">
                                <h5 className="font-size-20">{this.state.activeCoaches}</h5>
                                <p className="text-muted">Active</p>
                            </Col>
                            <Col xs="3">
                                <h5 className="font-size-20">{this.state.inactiveCoaches}</h5>
                                <p className="text-muted">Inactive</p>
                            </Col>
                            <Col xs="3">
                                <h5 className="font-size-20">{this.state.suspendedCoaches}</h5>
                                <p className="text-muted">Suspended</p>
                            </Col>
                            <Col xs="3">
                                <h5 className="font-size-20">{this.state.canceledCoaches}</h5>
                                <p className="text-muted">Canceled</p>
                            </Col>
                        </Row>

                        {/* <div id="morris-area-example" className="morris-charts morris-charts-height" dir="ltr">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="area" height="300" />
                        </div> */}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default MonthlyEarnings;