import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  isAllowed,
  logout,
  uInfo,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import Dropdown from 'react-bootstrap/Dropdown';

export default class UsersDatatableTables extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "-",
        key: "sr",
        cell: (record, index) => {
          return (
            <Fragment>
              <Dropdown className='align-self-center' onSelect={(e) => {
                if (e == 1) {
                  this.props.history.push("/teams/edit/" + bcrypt(record.id));
                } else if (e == 3) {
                  this.deleteRecord(record, index);
                } else {
                  console.log(e, 'unknown')
                }
              }}>
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <i className='fas fa-cog'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1"><i className="fas fa-edit"></i> Edit</Dropdown.Item>
                  
                  <Dropdown.Item eventKey="3"><i className="fas fa-trash"></i> Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Fragment>
          )
        },
      },
     
      {
        text: "Team Name",
        key: "name",
      },
       {
        text: "Agents Assigned",
        key: "agents",
      },
     
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      settingchangemodal: false,
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      coachlimit: "",
      page_name: "Teams",
      customDate: true,
      ClientSubcribeModal: false,
      currentClient: {
        id: "",
        email_status: "",
        call_status: "",
        groups: [],
        tags: [],
      },
    };

    this.deleteRecord = this.deleteRecord.bind(this)

  }


  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/team", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id, coach_id: record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  componentDidMount() {
    this.getData();
  }
  closemodal() {
    this.setState({
      settingchangemodal: !this.state.settingchangemodal,
    });
  }
  checkcoachlimit = () => {
    this.props.history.push("/teams/add");
  };
  getData = (queryString = "?all=1", data) => {
    fetch(`${ApiUrl}` + "teams" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            //     roles: response.data.roles,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString + "&all=1", this.state.filter);
  };


  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col className="col-12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name} List</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name} List</Link>
                    </li>
                  </ol>
                </div>
              </Col>

              <Col sm={2}>

              </Col>
              <Col sm={4}>
                <div className="page-title-box text-align-right">
                  {/* && this.state.coachlimit.remaining_user > 0 */}
                  {(isAllowed(uInfo(), ["can_create"])) && (
                    <Link
                      type="button"
                      color="info"
                      className="btn btn-info"
                      onClick={() => this.checkcoachlimit()}
                    >
                      <i className="mdi mdi-plus"> </i>
                      Create
                    </Link>
                  )}

                </div>
              </Col>
            </Row>

            <ToastContainer />


            <Card>
              <CardBody>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}
